<div class="col-lg-12 container">

  <h4 class="description-title">Hochgeladene Dateien</h4>

  <ng-container *ngIf="downloadedFilesList">
    <div class="file" *ngFor="let downloadedFile of downloadedFilesList">
      <div class="displayFile">
        <app-icons class="icon" [iconName]="'mountain'"></app-icons>
        <div class="text">
          <p class="font-c2">{{ downloadedFile.name }}</p>
          <p class="font-c2 description">
            {{ '.'+downloadedFile.type.split('/')[1]! }} |
            {{ downloadedFile.size | bytesToSize }} |
            {{ (downloadedFile.date | date:'dd.MM.yyyy h:mm') + ' Uhr'}}
          </p>
        </div>
      </div>
      <div class="buttonGroup">
        <app-icon-text-button [buttonSecondary]="true"
                              [backgroundColor]="'bg-white'"
                              [iconName]="'download-icon'"
                              [iconSize]="'medium'"
                              [buttonText]="'Download'"
                              [textColor]="'red'"
                              [buttonLeft]="true"
                              (click)="downloadUploadedFile(downloadedFile)"
        ></app-icon-text-button>
        <app-icons class="delete" iconName="delete-icon" (click)="this.deleteUploadedFile(downloadedFile)"></app-icons>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="selectedFiles">
    <div class="file" *ngFor="let selectedFile of allFiles">
      <div class="displayFile">
        <app-icons class="icon" [iconName]="'mountain'"></app-icons>
        <div class="text">
          <p class="font-c2">{{ selectedFile.name }}</p>
          <p class="font-c2 description">
            {{ '.'+selectedFile.type.split('/')[1]! }} |
            {{ selectedFile.size | bytesToSize }} |
            {{ (selectedFile.date | date:'dd.MM.yyyy h:mm') + ' Uhr'}}
          </p>
        </div>
      </div>

      <div class="buttonGroup">
        <app-icon-text-button [buttonSecondary]="true"
                              [backgroundColor]="'bg-white'"
                              [iconName]="'download-icon'"
                              [iconSize]="'medium'"
                              [buttonText]="'Download'"
                              [textColor]="'red'"
                              [buttonLeft]="true"
                              (click)="downloadFile(selectedFile)"
        ></app-icon-text-button>
        <app-icons class="delete" iconName="delete-icon" (click)="this.fileUploadService.delete(selectedFile)"></app-icons>
      </div>

    </div>

  </ng-container>
</div>












