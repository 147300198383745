<ng-container *transloco="let t" >
  <div id="dashboard-media" class="row">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div class="col-lg-12 media-description">
        <app-media-box  [isVideo]="true" [videoName]="'how-to.mp4'"></app-media-box>
        <app-media-box [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
      </div>
    </div>
  </div>

  <ng-template #mediaBoxContent>
      <app-icons class="question-icon" [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
       <h5 class="text-center" [innerHTML]="'dashboard.mediaPart.helpTitle' | transloco"></h5>
      <a href="mailto:sponsoring.regional@ergo.de"><p class="font-c3 text-center">{{t('dashboard.mediaPart.helpDescription')}}</p></a>
  </ng-template>
</ng-container>
