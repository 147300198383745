{{fieldType}}
<ng-container [formGroup]="form">
<div [formGroupName]="groupName">
      <ng-container [ngSwitch]="fieldType" formGroupName="firstYear">
        <mat-form-field class="full-width" appearance="fill" *ngSwitchCase="'string'" >
          <label class="font-c2">{{ label }}</label>
            <input  matInput [formControlName]="controlName"/>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill" *ngSwitchCase="'number'" >
          <label class="font-c2">{{ label }}</label>
          <input type="number" matInput [formControlName]="controlName"/>
        </mat-form-field>

        <ng-container *ngSwitchCase="'boolean'" class="radio-button-group">
          <mat-radio-group [formControlName]="controlName">
            <mat-radio-button [value]="true">Ja</mat-radio-button>
            <mat-radio-button [value]="false">Nein</mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <mat-form-field class="col-lg-6" *ngSwitchCase="'date'">
          <label class="font-c2">{{ label }}</label>
          <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

      </ng-container>
</div>

</ng-container>
