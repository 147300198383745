<ng-container *transloco="let t" [formGroup]="form" id="sponsoring-value">
  <app-stepper></app-stepper>
  <div class="container-wizard">
    <app-wizard-header [currentComponent]="'sponsoring-value'"
                       [title]="t('wizard.sponsoringValue.title')"
                       [subtitle]="t('wizard.sponsoringValue.subtitle')">
    </app-wizard-header>

    <div class="container container-value">
      <ng-container *ngIf="isSponsoringDurationOneYear() else twoYears">
        <app-sponsoring-value-one-year></app-sponsoring-value-one-year>
      </ng-container>
      <ng-template #twoYears>
        <app-sponsoring-value-two-years></app-sponsoring-value-two-years>
      </ng-template>
    </div>
  </div>
</ng-container>
