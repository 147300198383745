import {Component, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {TranslocoModule} from "@ngneat/transloco";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {MatCalendar, MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {MatCard} from "@angular/material/card";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {min, Subscription} from "rxjs";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {MatError} from "@angular/material/form-field";
import {DatePipe, NgIf} from "@angular/common";
import {ValidationService} from "../../../shared/services/validation-service";

@Component({
  selector: 'app-wizard-sponsoring-end',
  standalone: true,
  imports: [
    TranslocoModule,
    TextButtonComponent,
    WizardHeaderComponent,
    MatCalendar,
    MatCard,
    ReactiveFormsModule,
    MatDatepicker,
    MatDatepickerModule,
    WizardFooterComponent,
    StepperComponent,
    MatError,
    NgIf,
    DatePipe,
  ],
  providers: [
    MatDatepickerModule,
  ],
  templateUrl: './sponsoring-end-date.html',
  styleUrl: './sponsoring-end-date.scss'
})
export class SponsoringEndDate implements OnInit{
  form = this.formService.buildForm();
  sponsoringStartDateControl: FormControl = this.form.get('sponsoringValue.sponsoringStartDate') as FormControl;
  private sponsoringStartDateSubscription: Subscription = {} as Subscription;
  test = this.form.get('sponsoringValue.sponsoringStartDate')?.value


  constructor(public wizardService: WizardService, public formService: FormService, public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
  }


  ngOnInit() {
    this.sponsoringStartDateSubscription = this.sponsoringStartDateControl.valueChanges.subscribe(() => {
      this.setMinDate();
    });
  }

  setMinDate(): Date {
    const sponsoringStartDateValue: Date = this.form.get('sponsoringValue.sponsoringStartDate')?.value;
    const minDate: Date = new Date(sponsoringStartDateValue);
    minDate.setDate(minDate.getDate());
    return minDate;
  }

  setMaxDate(): Date {
    const sponsoringStartDateValue: Date = this.form.get('sponsoringValue.sponsoringStartDate')?.value;
    const minDate: Date = new Date(sponsoringStartDateValue);
    const maxDate: Date = new Date(minDate);
    maxDate.setFullYear(minDate.getFullYear() + 2);
    maxDate.setDate(minDate.getDate() + 1);
    return maxDate;
  }

  updateFormDate(date: Date | null) {
    if (date) {
      const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0); // Set time to noon
      this.form.get('sponsoringValue.sponsoringEndDate')?.setValue(adjustedDate);
    } else {
      this.form.get('sponsoringValue.sponsoringEndDate')?.setValue(null);
    }
  }
}
