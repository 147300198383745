import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private files: any[] = [];

  setData(fileData: any): void {
    this.files.push(fileData);
  }

  getData(): any[] {
    return this.files;
  }

  clearData(): void {
    this.files = [];
  }
}
