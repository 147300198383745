<ng-container *transloco="let t" id="global-consent" [formGroup]="form">
  <app-stepper></app-stepper>
  <div class="container-wizard">
    <app-wizard-header [title]="t('wizard.consent.title')"></app-wizard-header>
    <ng-container class="row" id="consent-checkboxes" formGroupName="consent">
      <div class="container consent">
        <h3 class="subtitle">Bestätigungen</h3>
        <mat-checkbox [ngClass]="checkboxError('goal')" class="full-with" formControlName="goal">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.goal')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('reputation')" class="full-with" formControlName="reputation">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.reputation')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('discrimination')" class="full-with" formControlName="discrimination">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.discrimination')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('politics')" class="full-with" formControlName="politics">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.politics')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('law')" class="full-with" formControlName="law">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.law')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('religion')" class="full-with" formControlName="religion">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.religion')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('balance')" class="full-with" formControlName="balance">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.balance')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('fix')" class="full-with" formControlName="fix">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.fix')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('affectionPerson')" class="full-with" formControlName="affectionPerson">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.affectionPerson')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('affectionEmployee')" class="full-with" formControlName="affectionEmployee">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.affectionEmployee')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('gremium')" class="full-with" formControlName="gremium">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.gremium')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('consentUsingRegulationRules')" class="full-with" formControlName="consentUsingRegulationRules">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.consentUsingRegulationRules')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('consentUsingHint')" class="full-with" formControlName="consentUsingHint">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.consentUsingHint')"></p>
        </mat-checkbox>

        <mat-checkbox [ngClass]="checkboxError('currentSponsoringRules')" class="full-with" formControlName="currentSponsoringRules">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.currentSponsoringRules')"></p>
        </mat-checkbox>

        <div class="special-section"></div>

        <h3 class="subtitle">Informationspflicht</h3>
        <mat-checkbox [ngClass]="checkboxError('information')" class="full-with" formControlName="information">
          <p class="font-c2" [innerHTML]="t('wizard.consent.consentElements.information')"></p>
        </mat-checkbox>
      </div>
    </ng-container>

    <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isConsentInvalid(form)"></app-wizard-footer>
  </div>
</ng-container>
