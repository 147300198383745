import {Component, Input} from '@angular/core';
import {translate, TranslocoModule} from "@ngneat/transloco";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {Router, RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";
import {DataService} from "../../../core/services/data.service";

@Component({
  selector: 'app-add-new-application',
  standalone: true,
  imports: [TranslocoModule, IconsComponent, IconTextButtonComponent, NgIf, MatCard, RouterLink],
  templateUrl: './add-new-application.component.html',
  styleUrl: './add-new-application.component.scss'
})
export class AddNewApplicationComponent {
  @Input() emptyDashboard = true;
  activeUserRole = '';

  constructor(private router: Router, public dataService: DataService) {
    this.dataService.getUserRole().subscribe((result)=>{
      this.activeUserRole = result.role.toUpperCase();
    })
  }

  addNewApplication(): void {
    this.router.navigate(['/duration']);
  }

  getTitle(): string {
    switch (this.activeUserRole) {
      case UserRoleEnum.VKF:
        return 'Es gibt noch keine Anträge oder Benachrichtigungen';
      case UserRoleEnum.RD:
        return 'Es gibt noch keine Anträge oder Benachrichtigungen';
      case UserRoleEnum.RD_SUPERVISOR:
        return 'Es gibt noch keine Anträge oder Benachrichtigungen';
      case UserRoleEnum.APPLICANT:
        return "Sie haben noch keinen Antrag erstellt";
      default:
        return '';
    }
  }


  getDescription(): string {
    switch (this.activeUserRole) {
      case UserRoleEnum.VKF:
        return 'Sobald ein Antrag erstellt wurde, wird dieser hier angezeigt.';
      case UserRoleEnum.RD:
        return 'Sobald ein Antrag erstellt wurde, wird dieser hier angezeigt.';
      case UserRoleEnum.RD_SUPERVISOR:
        return 'Sobald ein Antrag erstellt wurde, wird dieser hier angezeigt.';
      case UserRoleEnum.APPLICANT:
        return "Sobald Sie einen Antrag erstellt haben, wird dieser hier angezeigt.";
      default:
        return '';
    }
  }

  protected readonly UserRoleEnum = UserRoleEnum;
}
