import { Component } from '@angular/core';
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {Router} from "@angular/router";
import {NavigationComponent} from "../../../shared/components/navigation/navigation.component";

@Component({
  selector: 'app-header',
  standalone: true,
    imports: [
        TextButtonComponent,
        NavigationComponent
    ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(private router: Router) {
    // noop
  };

  navigateToDashboard():void {
    localStorage.removeItem('form-data');
    this.router.navigate(['/dashboard']);
  }
}
