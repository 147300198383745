import {Component} from '@angular/core';
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {NgClass, NgForOf} from "@angular/common";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    NgForOf,
    NgClass
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
  selectedMenuIndex: number | null = null;

  applicantNavigation = [
    {label: 'Dashboard', path: '/applicant/dashboard'},
    {label: 'Profile', path: '/applicant/profile'},
  ];

  vkfNavigation = [
    {label: 'Dashboard', path: '/dashboard'},
    {label: 'Anträge', path: ''},
    {label: 'Reporting & Controlling', path: ''},
    {label: 'Antragsteller', path: ''},
    {label: 'Gesponserte', path: ''},
  ];

  constructor(private router: Router,) {}

  onMenuClick(menuElement: any, index: number) {
    this.selectedMenuIndex = index;
    if(menuElement.path !== ''){
      this.router.navigate([menuElement.path]);
    }
  }

  getActiveMenuForSelectedView() {
    return this.applicantNavigation;

    /*
    const userRole = UserRoleEnum.VKF;
    switch (userRole) {
      case UserRoleEnum.APPLICANT:

      case UserRoleEnum.VKF:
        return this.vkfNavigation;
      default:
        return [];
    }*/
  }

  protected readonly JSON = JSON;
}
