<ng-container *transloco="let t">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.sponsoringConfirmation.title')"></app-wizard-header>

  <div id="sponsoring-confirmation" class="row">
    <div class="container">
      <app-key-value-card-component [title]="t('wizard.sponsoringConfirmation.tableHeader')"
                                    [requestedFormGroupName]="'sponsoringValue'"
      ></app-key-value-card-component>
    </div>
  </div>

  <app-wizard-footer textInput="Ja, die Angaben sind korrekt"></app-wizard-footer>
</ng-container>
