<ng-container *transloco="let t" [formGroup]="form" id="sponsoring-value">
  <app-stepper></app-stepper>
  <app-wizard-header [currentComponent]="'sponsoring-value'"
                     [title]="t('wizard.sponsoringValue.title')"
                     [subtitle]="t('wizard.sponsoringValue.subtitle')"></app-wizard-header>

  <div class="container">
    <ng-container *ngIf="isSponsoringDurationOneYear() else twoYears">
      <app-sponsoring-value-one-year></app-sponsoring-value-one-year>
    </ng-container>
    <ng-template #twoYears>
      <app-sponsoring-value-two-years></app-sponsoring-value-two-years>
    </ng-template>
  </div>
</ng-container>
