<ng-container *transloco="let t">
  <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue?.application_state"
                     [title]="editMode ?
                     t('wizardTitles.vkfOverviewCheckTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
                     : t('wizardTitles.vkfOverviewTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
">

  </app-wizard-header>

  <div class="container">
    <div *ngIf="!editMode else editModeTemplate">
      <mat-card>
        <h3>{{ this.stateTextService.returnTextForBox(this.applicationData?.sponsoringValue?.application_state).title }}</h3>
        <p
          class="element-space">{{ this.stateTextService.returnTextForBox(this.applicationData?.sponsoringValue?.application_state, applicationData?.sponsoringValue?.sponsoringStartDate).content }}</p>
        <div class="buttonGroup">
          <app-text-button [buttonText]="'testPr'" (click)="switchMode(false)"></app-text-button>

          <ng-container *ngFor="let button of this.stateTextService.returnTextForBox(this.applicationData?.sponsoringValue?.application_state, '').button; let i = index">
            <app-text-button
              [isButtonReadOnly]="button.disabled"
              (click)="switchMode(button.disabled)"
              [buttonText]="button.text"
              [isPrimaryButton]="isButtonPrimary(i)">
            </app-text-button>
          </ng-container>
        </div>
      </mat-card>
    </div>

    <form [formGroup]="form">
      <div *ngFor="let section of sections">
        <ng-container>

          <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
            <app-financial-assistance-key-value-component
              [data]="applicationData"></app-financial-assistance-key-value-component>
          </ng-container>

          <ng-template #defaultKeyValues>
            <app-key-value-card-component
              [isEditable]="false"
              [title]="t(section.title)"
              [data]="applicationData?.[section.dataKey]">
            </app-key-value-card-component>
          </ng-template>

          <ng-container *ngIf="editMode" [formGroupName]="'comments'">
            <h5>Kommentar</h5>
            <textarea [formControlName]="section.id"
                      placeholder="min. 10 Zeichen"
                      class="col-lg-12 sectionSpaceDefault">
              </textarea>
            <h5>Historie der Kommentare</h5>
            <ng-container *ngFor="let comment of getCommentsForSection(section.id)">
              <div class="col-lg-12 element-space">
                <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
                  am {{ comment.created_at | date:'dd.MM.yyyy' }}</h5>
                <p class="font-c2">{{ comment.content }}</p>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </form>

    <app-file-viewer></app-file-viewer>

    <ng-container *ngIf="!editMode">
      <div class="sectionSpaceDefault">
        <h4>Internen Anmerkungen</h4>
        <textarea [(ngModel)]="internalNote"
                  placeholder="min. 10 Zeichen"
                  class="col-lg-12 element-space" [minlength]="10">
      </textarea>
        <app-text-button [isPrimaryButton]="true" [buttonText]="'Speichern'"
                         (click)="saveInteranlNote(internalNote)"></app-text-button>
      </div>

      <ng-container>
        <h4>Historie der Internen Anmerkungen</h4>
        <ng-container *ngFor="let note of applicationData?.internalNotes">
          <div class="col-lg-12 element-space">
            <h5 class="col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ note.authorName }}
              am {{ note.created_at | date:'dd.MM.yyyy' }}</h5>
            <p class="font-c2">{{ note.note }}</p>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>


    <div *ngIf="editMode">
      <div class="col-lg-12 buttonGroup">
        <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.rejectApplication')"
                         (click)="changeStateToCorrectionsNeeded('rejected')"></app-text-button>
        <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.correctionNeeded')"
                         (click)="changeStateToCorrectionsNeeded('correctionNeeded')"></app-text-button>
        <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.acceptApplication')"
                         (click)="changeStateToCorrectionsNeeded('accepted')"></app-text-button>
      </div>
    </div>

    <ng-template #editModeTemplate>
      <app-hint [hintDescription]="t('vkf.detailView.hint')"></app-hint>
      <app-hint [hintDescription]="t('vkf.detailView.hintComment')"></app-hint>
    </ng-template>
  </div>
</ng-container>
