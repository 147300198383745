import {Injectable} from "@angular/core";
import {DataService} from "../../../core/services/data.service";

@Injectable({
  providedIn: 'root'
})


export class ApplicantDetailOverviewService {


  constructor(private dataService: DataService) {}

  getDocumentsForSelectedApplication(applicationId: string): void {
    this.dataService.getContractDocuments(applicationId).subscribe((result) => {
      console.log(result, '**reeees');
    });
  }



}
