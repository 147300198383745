<ng-container *transloco="let t">
  <mat-card class="card-body">
    <div class="row boxInput">
      <div class="col-sm-2 col-md-1 col-lg-1">
        <div class="box">
          <app-icons [iconName]="'glocke'" [iconSize]="'medium'" [bgColor]="'warningRed'"
                     [circleIcon]="true"></app-icons>
        </div>
      </div>
      <div class="col-sm-10 col-md-11 col-lg-11">
        <div class="box">
          <!-- Todo  die letzten drei Anträge ?? -->
          <div class="box-header">
            <div>
              <h4>{{notification.subject}}</h4>
              <p class="font-c2">{{ notification.text }}</p>
            </div>


            <div>
              <p class="font-c2">{{notification.created_at | date: 'dd.MM.yyyy'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
