<ng-container *transloco="let t">
  <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue?.application_state_label"
                     [title]="editMode ?
                     t('wizardTitles.vkfOverviewCheckTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
                     : t('wizardTitles.vkfOverviewTitle',
                     {organisation: this.applicationData?.sponsored.sponsoredName})
">
  </app-wizard-header>
  <div class="container">
      <mat-card>
          <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.REJECTED_RD_LEITER" [iconName]="'rejection-icon'" [iconSize]="'large'"
                     [circleIcon]="true"></app-icons>
          <app-icons class="icon" *ngIf="this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.APPROVED_RD_LEITER" [iconName]="'check-icon'" [iconSize]="'large'"
                   [circleIcon]="true"></app-icons>

        <ng-container *ngIf="this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state_label).title !== ''">

        </ng-container>
        <h3>{{ this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state).title }}</h3>
        <p
          class="element-space">{{ this.stateTextService.returnTextForRDLeiter(this.applicationData?.sponsoringValue?.application_state, applicationData?.sponsoringValue?.sponsoringStartDate).content }}</p>
      </mat-card>

    <form [formGroup]="form">
      <div *ngFor="let section of sections">
        <ng-container>

          <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
            <app-financial-key></app-financial-key>
          </ng-container>

          <ng-template #defaultKeyValues>
            <app-key-value-card-component
              [isEditable]="false"
              [title]="t(section.title)"
              [data]="applicationData?.[section.dataKey]">
            </app-key-value-card-component>
          </ng-template>
        </ng-container>
      </div>
    </form>










      <ng-container *ngIf="(this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CHECK_VKF || this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.SPONSORING_APPROVED_VKF)
      else navigateDashboard">
        <div class="col-lg-12 buttonGroupSave">
          <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.rejectApplicationRD')"
                           (click)="openRejectionModal('rejected')"></app-text-button>
          <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.acceptApplicationRD')"
                           (click)="acceptSponsoring('accepted')"></app-text-button>
        </div>
      </ng-container>
      <ng-template #navigateDashboard>
        <div class="col-lg-12 buttonGroup">
          <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.dashboard')"
                           (click)="navigateToDashboard()"></app-text-button>
        </div>
      </ng-template>
    </div>
</ng-container>
