import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApplicationDto} from "../../api/applicationDto";
import {API_URLS} from "../../api/_api_config";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  backendURL = environment.apiPath;
  token = '';

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService ) {
    // noop
  }

  getRolesForLoginPage(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token );
    const url = this.backendURL + API_URLS.GET_ROLES_FOR_LOGIN_PAGE;
    return this.http.get<ApplicationDto>(url, {headers})
  }

  setToken(link: string) {
    this.http.post<any>(link, {}).subscribe((result) => {
      this.token = 'Bearer ' + result.token;
      if( this.token){
        this.cookieService.set('user-token',  this.token);
        this.router.navigate(['/dashboard']);
      }
    });
  }

}
