import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormService} from "./form-service/form.service";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor(private formService: FormService) {
  }

  isFieldInvalid(fieldName: string, form: FormGroup): boolean {
    const control = form.get(fieldName);
    if (!control) {
      return false;
    }
    return control.invalid && control.touched;
  }

  isSponsoringValueOneYearInvalid(form: any): Array<FormControl> {
    const controls = [
      form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear'),
      form.get('sponsoringValue.sponsoringValueNetAmountFirstYear')
    ];
    return controls;
  }

  isSponsoringValueTwoYearsInvalid(form: any): Array<FormControl> {
    const controls = [
      form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear'),
      form.get('sponsoringValue.sponsoringValueNetAmountFirstYear'),
      form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear'),
      form.get('sponsoringValue.sponsoringValueNetAmountSecondYear')
    ];
    return controls;
  }

  isApplicantInvalid(form: FormGroup): Array<FormControl> {
    const controls: FormControl[] = [];
    const applicantGroup = form.get('applicant') as FormGroup;

    if (applicantGroup) {
      Object.keys(applicantGroup.controls).forEach(controlName => {
        const control = applicantGroup.get(controlName) as FormControl;
        controls.push(control);
      });
    }
    return controls;
  }

  isSponsoredInvalid(form: FormGroup): Array<FormControl> {
    const controls: FormControl[] = [];
    const sponsored = form.get('sponsored') as FormGroup;

    if (sponsored) {
      Object.keys(sponsored.controls).forEach(controlName => {
        const control = sponsored.get(controlName) as FormControl;
        controls.push(control);
      });
    }
    return controls;
  }

  isFinancialAssistanceInvalid(form: FormGroup, isOneYear?: boolean, isError?: boolean): Array<FormControl> {
    const controls: any[] = [];
    const arrayFirstYear = form.get('financialAssistance.firstYear.rates')?.value as FormArray;
    const arraySecondYear = form.get('financialAssistance.secondYear.rates')?.value as FormArray;
    const isPaymentMethodFullPaymentFirstYear = form.get(`financialAssistance.firstYear.fullPayed`)?.value;
    const isPaymentMethodFullPaymentSecondYear = form.get(`financialAssistance.secondYear.fullPayed`)?.value;

    if (arrayFirstYear && Array.isArray(arrayFirstYear)) {
      arrayFirstYear.forEach((item, index) => {

        if(isPaymentMethodFullPaymentFirstYear) {
          controls.push(form.get('financialAssistance.firstYear.rates.' + index + '.date'));
          form.get('financialAssistance.firstYear.rates' + index + '.value')?.clearValidators();
        } else {
          controls.push(form.get('financialAssistance.firstYear.rates.' + index + '.date'));
          controls.push(form.get('financialAssistance.firstYear.rates.' + index + '.value'));
        }
      });
    }

    if (!isOneYear && arraySecondYear && Array.isArray(arraySecondYear)) {
      arraySecondYear.forEach((item, index) => {
        if(isPaymentMethodFullPaymentSecondYear) {
          controls.push(form.get('financialAssistance.secondYear.rates.' + index + '.date'));
          form.get('financialAssistance.firstYear.rates' + index + '.value')?.clearValidators();
        } else {
          controls.push(form.get('financialAssistance.secondYear.rates.' + index + '.date'));
          controls.push(form.get('financialAssistance.secondYear.rates.' + index + '.value'));
        }
      });
    }

    if (form.get('financialAssistance.repetition')?.value) {
      controls.push(form.get('financialAssistance.repetitionYear'));
    }
    return controls;
  }

  validateSponsoringRatesTotal(form: FormGroup, year: 'firstYear' | 'secondYear'): boolean {
    const capitalizedYear = year.charAt(0).toUpperCase() + year.slice(1);
    const isPaymentMethodFullPayment = form.get(`financialAssistance.${year}.fullPayed`)?.value;
    const sponsoringValueGross = form.get(`sponsoringValue.sponsoringValueGrossAmount${capitalizedYear}`)?.value;
    const sponsoringValueNet = form.get(`sponsoringValue.sponsoringValueNetAmount${capitalizedYear}`)?.value;

    const sponsoringValue = sponsoringValueGross !== null && sponsoringValueGross !== undefined
      ? sponsoringValueGross
      : sponsoringValueNet;

    const ratesPath = `financialAssistance.${year}.rates`;
    const rates = form.get(ratesPath)?.value || [];

    const ratesSum = Array.isArray(rates)
      ? rates.reduce((acc, current) => acc + (current.value || 0), 0)
      : 0;

    const roundedRatesSum = parseFloat(ratesSum.toFixed(2));
    const roundedSponsoringValue = parseFloat((sponsoringValue || 0).toFixed(2));
    return !isPaymentMethodFullPayment && roundedRatesSum !== roundedSponsoringValue;
  }

  isEntitlementsInvalid(form: any): Array<FormControl> {
    const controls = [
      form.get('entitlements.services.0'),
      form.get('entitlements.services.1'),
      form.get('entitlements.hospitality.hospitalityValue'),
      form.get('entitlements.titleRight')
    ];
    form.get('entitlements.hospitality.isHospitality')?.value ? controls.push(form.get('entitlements.hospitality.hospitalityService')) : [];

    return controls;
  }

  isConsentInvalid(form: FormGroup): FormControl[] {
    const controls: FormControl[] = [];
    const consent = form.get('consent') as FormGroup;

    if (consent) {
      Object.keys(consent.controls).forEach(controlName => {
        const control = consent.get(controlName) as FormControl;
        if (control.errors) {
          controls.push(control);
        }
      });
    }
    return controls;
  }

  isEndDateInvalid(form: FormGroup): FormControl[] {
    const controls: FormControl[] = [];
    const startDateControl = form.get('sponsoringValue.sponsoringStartDate') as FormControl;
    const endDateControl = form.get('sponsoringValue.sponsoringEndDate') as FormControl;

    if (startDateControl?.value == null) {
      startDateControl?.setErrors({required: true});
      controls.push(startDateControl);
    }

    if (endDateControl?.value == null) {
      endDateControl?.setErrors({required: true});
      controls.push(endDateControl);
    } else if (startDateControl?.value && startDateControl.value > endDateControl.value) {
      endDateControl.setErrors({endBeforeStart: true});
      controls.push(endDateControl);
    } else {
      endDateControl.setErrors(null);
    }

    return controls;
  }
}
