<mat-card *transloco="let t" [formGroup]="form" class="col-lg-12">

  <mat-card-title class="title">Sponsoringsumme, Zuschuss und Wiederholung
    <div class="button-group">
      <button *ngIf="isEditable" (click)="openEditDialog('300ms', '200ms')">
        <p class="font-c2">Bearbeiten</p>
        <mat-icon class="icon" svgIcon="edit-icon"></mat-icon>
      </button>
      <button (click)="closeCard()">
        <mat-icon class="icon" [svgIcon]="isCardOpen ? 'open-accordion' : 'close-accordion'"></mat-icon>
      </button>
    </div>
  </mat-card-title>

  <mat-card-content *ngIf="applicationData">
    <!-- first -->
    <div id="firstFullPayed" class="item">
      <div class="key-value-pair row">
        <div class="key col-lg-6">{{ getLabelForFullPayedFirstYear() }}</div>
        <div class="value col-lg-6">
          {{
            getSavedDataValues ?
              t('common.fullPayment.' + (this.applicationData.financialAssistance.firstYear.fullPayed)) :
              t('common.fullPayment.' + this.form.get('financialAssistance.firstYear.fullPayed')?.value)
          }}
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="this.getSavedDataValues ? !this.applicationData.financialAssistance.firstYear.fullPayed : !this.form.get('financialAssistance.firstYear.fullPayed')?.value">
      <div id="rateAmountFirstYear" class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">In wievielen Raten?</div>
          <div class="value col-lg-6">
            {{getSavedDataValues ? this.applicationData.financialAssistance.firstYear.rateAmount : this.form.get('financialAssistance.firstYear.rateAmount')?.value }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="this.getSavedDataValues ?
     this.applicationData.financialAssistance.firstYear.fullPayed:
     this.form.get('financialAssistance.firstYear.fullPayed')?.value else rateFields">
      <div id="fullPayedDate" class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">Wann wird der Gesamtbetrag fällig?</div>
          <div class="value col-lg-6">
            {{ getSavedDataValues ?
              (this.applicationData.financialAssistance.firstYear.rates[0]?.date | date: 'dd.MM:YYYY') :
              (this.form.get('financialAssistance.firstYear.rates')?.value[0]?.date | date: 'dd.MM.YYYY') }}
          </div>
        </div>
      </div>
    </ng-container>


    <ng-template #rateFields>
      <div id="rateArrayFirstYear" *ngFor="let rate of getSavedDataValues ? applicationData.financialAssistance.firstYear.rates : form.get('financialAssistance.firstYear.rates')?.value; let i = index">
        <div class="item-part">
          <h5>{{ t('formFieldMapping.financialAssistance.rates.' + (i + 1)) }}</h5>
          <div class="key-value-pair row">
            <div class="key col-lg-6">{{ bruttoIsNetto ? 'Netto-Betrag' : 'Brutto-Betrag' }}aa</div>
            <div class="value col-lg-6">{{ rate.value }}€</div>
          </div>
          <div class="key-value-pair row">
            <div class="key col-lg-6">Fälligkeit</div>
            <div class="value col-lg-6">{{ rate.date | date: 'dd.MM.YYYY' }}</div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- second -->


    <ng-container *ngIf="sponsoringDurationTwoYears">
      <div id="secondFullPayed" class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">{{ getLabelForFullPayedSecondYear() }}</div>
          <div class="value col-lg-6">
            {{
              getSavedDataValues ?
                t('common.fullPayment.' + (this.applicationData.financialAssistance.secondYear.fullPayed)) :
                t('common.fullPayment.' + this.form.get('financialAssistance.secondYear.fullPayed')?.value)
            }}
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="this.getSavedDataValues ? !this.applicationData.financialAssistance.secondYear.fullPayed : !this.form.get('financialAssistance.secondYear.fullPayed')?.value">
        <div id="rateAmountSecondYear" class="item">
          <div class="key-value-pair row">
            <div class="key col-lg-6">In wievielen Raten?</div>
            <div class="value col-lg-6">
              {{getSavedDataValues ? this.applicationData.financialAssistance.secondYear.rateAmount : this.form.get('financialAssistance.secondYear.rateAmount')?.value }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="this.getSavedDataValues ? this.applicationData.financialAssistance.secondYear.fullPayed:this.form.get('financialAssistance.secondYear.fullPayed')?.value else rateFieldsSecondYear">
        <div id="fullPayedDateFirstYear" class="item">
          <div class="key-value-pair row">
            <div class="key col-lg-6">Wann wird der Gesamtbetrag fällig?</div>
            <div class="value col-lg-6">

              {{ getSavedDataValues ?
              (this.applicationData.financialAssistance.secondYear.rates[0]?.date | date: 'dd.MM:YYYY') :
              (this.form.get('financialAssistance.secondYear.rates')?.value[0]?.date | date: 'dd.MM.YYYY') }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #rateFieldsSecondYear>
        <div id="rateArraySecondYear" *ngFor="let rate of getSavedDataValues ? applicationData.financialAssistance.secondYear.rates : form.get('financialAssistance.secondYear.rates')?.value; let i = index">
          <div class="item-part">
            <h5>{{ t('formFieldMapping.financialAssistance.rates.' + (i + 1)) }}</h5>
            <div class="key-value-pair row">
              <div class="key col-lg-6">{{ bruttoIsNetto ? 'Netto-Betrag' : 'Brutto-Betrag' }}v</div>
              <div class="value col-lg-6">{{ rate.value }}€</div>
            </div>
            <div class="key-value-pair row">
              <div class="key col-lg-6">Fälligkeit</div>
              <div class="value col-lg-6">{{ rate.date | date: 'dd.MM.YYYY' }}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>




    <!-- other -->
    <div class="item">
      <div class="key-value-pair row">
        <div class="key col-lg-6">
          {{ t('formFieldMapping.financialAssistance.zuschuss.label') }}
        </div>
        <div class="value col-lg-6">
          {{ t('common.' + getZuschussValue()) }}
        </div>
      </div>
    </div>

    <div class="item">
      <div class="key-value-pair row">
        <div class="key col-lg-6">
          {{ t('formFieldMapping.financialAssistance.repetition.label') }}
        </div>
        <div class="value col-lg-6">
          {{ t('common.' + getRepetitionValue()) }} {{ getRepetitionYear() }}
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
