<ng-container *transloco="let t">
  <div class="col-lg-12 dataUpload">
    <div class="col-lg-5">
      <h4>{{ t("common.other") }}</h4>
      <p class="font-c3">{{ t("common.example") }}</p>
    </div>

    <div class="col-lg-7">
      <div class="col-lg-6">
        <input type="file" (change)="onFileSelected($event)" style="display: none" #fileInput multiple/>
        <app-text-button [isPrimaryButton]="false" [buttonText]="t('common.search')" (click)="fileInput.click()"></app-text-button>
      </div>
      <div class="col-lg-6 drop-zone">
        <mat-card class="box-data-padding" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
          <app-icons [circleIcon]="true" [bgColor]="'warningRed'" [iconSize]="'large'" [iconName]="'wolke'"></app-icons>
          <p class="font-c2">{{ t("common.dragDrop") }}</p>
        </mat-card>
      </div>
    </div>
  </div>

  <app-file-viewer [selectedFiles]="selectedFiles"></app-file-viewer>
</ng-container>
