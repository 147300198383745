import {ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {isObject, translate, TranslocoDirective} from "@ngneat/transloco";
import {MatDialog} from "@angular/material/dialog";
import {EditFormDialogComponent} from "../edit-form-dialog/edit-form-dialog.component";
import {FormService} from "../../services/form-service/form.service";
import {MatIcon} from "@angular/material/icon";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {IconTextButtonComponent} from "../button/icon-text-button/icon-text-button.component";
import {FormArray, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IconService} from "../../services/IconService";

@Component({
  selector: 'app-key-value-card-component',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatCardContent,
    NgForOf,
    TranslocoDirective,
    NgIf,
    MatIcon,
    IconTextButtonComponent,
    ReactiveFormsModule,
    KeyValuePipe,
    NgClass,
  ],
  providers: [FileTypePipe],
  templateUrl: './key-value-card-component.component.html',
  styleUrl: './key-value-card-component.component.scss'
})
export class KeyValueCardComponentComponent implements OnChanges{
  @Input() title: string = 'Key-Value Table';
  @Input() extended: boolean = true;
  @Input() requestedFormGroupName = '';
  @Input() isEditable = false;
  @Input() comment = false;
  @Input() inputForm = new FormGroup({});
  @Input() data: any;
  @Input() serverData: any;
  @Input() marcCorrectionArea = false;

  form = this.formService.buildForm();
  commentForm = this.formService.commentForm;
  isCardOpen: boolean = true;

  fieldsToDisable = [
    'sponsoringDuration',
    'id',
    'funding_amount',
    'application_state',
    'application_state_label'
  ];

  constructor(public formService: FormService,
              public fileTypePipe: FileTypePipe,
              public datePipe: DatePipe,
              public iconService: IconService,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
    this.formService.initializeForm(this.form);
  }

  ngOnChanges(): void {
    this.formService.initializeForm(this.inputForm);
    this.getFormControlsAndValues();
  }
  getFlattenedFormValues() {
    const flattenObject = (obj: any, parentKey: string = '', result: any = {}) => {
      for (let key in obj) {
        if(this.serverData?.sponsoringValue?.sponsoringDuration === 1) {
          this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
          this.fieldsToDisable.push('sponsoringValueNetAmountSecondYear');
        }
        if(!this.fieldsToDisable.includes(key)) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const newKey = parentKey ? `${parentKey}.${key}` : key;

            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (item && typeof item === 'object') {
                  flattenObject(item, `${newKey}[${index}]`, result);
                } else {
                  result[`${newKey}[${index}]`] = item;
                }
              });
            } else if (value && typeof value === 'object') {
              flattenObject(value, newKey, result);
            } else {
              result[newKey] = value;
            }
          }

        }
      }
      return result;
    };
    return flattenObject(this.data);
  }
  getFormControlsAndValues(): any {
    const valuesList: { key: string; value: any }[] = [];
    const entries = Object.entries(this.form.get(this.requestedFormGroupName)?.value || {});

    const processEntries = (entries: [string, any][], parentFormGroup: string = ''): void => {
      entries.forEach(([key, value]) => {
        const fullKey = parentFormGroup ? `${parentFormGroup}.${key}` : key;
        if(this.form.get('sponsoringValue.sponsoringDuration')?.value === 1) {
          this.fieldsToDisable.push('sponsoringValueGrossAmountSecondYear');
          this.fieldsToDisable.push('sponsoringValueNetAmountSecondYear');
        }
        if (this.fieldsToDisable.includes(fullKey)) {
          return;
        }

        if (isObject(value)) {
          processEntries(Object.entries(value), fullKey);
        } else if (Array.isArray(value)) {
          value.forEach((arrayValue, index) => {
            const arrayKey = `${fullKey}-${index + 1}`;
              valuesList.push({key: arrayKey, value: arrayValue});
          });
        } else {
          valuesList.push({key: fullKey, value: value});
        }
      });
    };
    processEntries(entries);
    return valuesList;
  }

  openEditDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    const dialogRef = this.dialog.open(EditFormDialogComponent, {
      width: '920px',
      height:'75vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: this.data && this.localStorage.getItem('form-data') === null ?
        {value: this.data, name: this.requestedFormGroupName, serverData: this.serverData} :
        {name: this.requestedFormGroupName, serverData: null},
    });

    dialogRef.afterClosed().subscribe( (result) =>  {
      if(result){
        this.form = result;
        const services = this.form.get('entitlements.services') as FormArray
        services.clear();

        this.formService.initializeForm(this.form);
        this.cdr.detectChanges();
      }
    });
  }

  formattedValue(item: any) {
    if(this.fileTypePipe.transform(item) === 'date'){
      return this.datePipe.transform(item, 'dd.MM.YYYY');
    }

    if(item === false){
      return translate('common.' + 'false');
    } else if (item === true){
      return translate('common.' + 'true');
    }
    return item;
  }


  translateDataKeys(data: any){
    const parts = data.split(/[\[\]]/);
    const taxFree = this.serverData?.sponsoringValue?.taxFree;
    const sponsoringDuration = this.serverData?.sponsoringValue?.sponsoringDuration;

    if(parts.length > 1 && parts[0] === 'services') {
      return translate('formFieldMapping.services.default', {count: (parseInt(parts[1]) +1)})
    }
    if (parts.includes('sponsoringValueGrossAmountFirstYear')
      || parts.includes('sponsoringValueNetAmountFirstYear')
      || parts.includes('sponsoringValueNetAmountSecondYear')
      || parts.includes('sponsoringValueGrossAmountSecondYear')) {
      if(sponsoringDuration === 2) {
        if(taxFree){
          return translate('formFieldMapping.sponsoringValueTwoYears.taxFree.' + data);
        } else {
          return translate('formFieldMapping.sponsoringValueTwoYears.' + data );
        }
      } else {
        if(taxFree) {
          return translate('formFieldMapping.sponsoringValueOneYear.taxFreeFirstYear')
        } else {
          return translate('formFieldMapping.sponsoringValueOneYear.' + data );
        }
      }
    } else {
      return translate ('formFieldMapping.' + data)
    }
  }

  translateKeys(item: any) {
    const splitKey = item.split('-');
    if (splitKey.length > 1) {
      return translate('formFieldMapping.' + splitKey[0] + '.default', { count: splitKey[1] });
    }

    if (item === 'sponsoringValueGrossAmountFirstYear'
      || item === 'sponsoringValueNetAmountFirstYear'
      || item === 'sponsoringValueNetAmountSecondYear'
      || item === 'sponsoringValueGrossAmountSecondYear') {
      return this.returnTextForSponsoringValue(item);
    } else {
      return translate('formFieldMapping.' + item);
    }
  }


  returnTextForSponsoringValue(item: any) {
    const sponsoringDuration = this.form.get('sponsoringValue.sponsoringDuration')?.value;
    const taxFree = this.form.get('sponsoringValue.taxFree')?.value;
    if(sponsoringDuration === 2) {
      if(taxFree){
        return translate('formFieldMapping.sponsoringValueTwoYears.taxFree.' + item);
      } else {
        return translate('formFieldMapping.sponsoringValueTwoYears.' + item );
      }
    } else {
      if(taxFree) {
        return translate('formFieldMapping.sponsoringValueOneYear.taxFreeFirstYear')
      } else {
        return translate('formFieldMapping.sponsoringValueOneYear.' + item );
      }
    }
  }


  getFormUnit(field: string | unknown): string {
    if(field === 'sponsoringDuration'){
      return this.form.get('sponsoringValue.sponsoringDuration')?.value ? 'Jahr':'Jahre'
    }
    if(
      field === 'sponsoringValueGrossAmountFirstYear' ||
      field === 'sponsoringValueNetAmountFirstYear' ||
      field === 'sponsoringValueGrossAmountSecondYear' ||
      field === 'sponsoringValueNetAmountSecondYear' ||
      field === 'rateAmount' ||
      field === 'zuschuss' ||
      field === 'hospitality.hospitalityValue'
    ){
      return '€'
    }
    return ''
  }
  closeCard(): void {
    this.isCardOpen = !this.isCardOpen;
  }

  protected readonly localStorage = localStorage;
}
