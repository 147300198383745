import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {API_URLS} from "../../api/_api_config";
import {ApplicationDto} from "../../api/applicationDto";
import {AuthenticationService} from "./authentication.service";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor(private http: HttpClient, private authService: AuthenticationService, private cookieService: CookieService) {
    // changes
  }
  private mockData = './mockDataService.json';
  backendURL = environment.apiPath;


  getApplicationById(applicationId: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token'));
    return this.http.get<ApplicationDto>(`${this.backendURL + API_URLS.GET_APPLICATION_BY_ID}${applicationId}`, {headers})
  }

  getAllApplicationsForDashboard(): Observable<ApplicationDto[]> {
    const headers = new HttpHeaders().set('Authorization', (this.cookieService.get('user-token')).toString() );
    const url = this.backendURL + API_URLS.GET_ALL_APPLICATIONS_FOR_DASHBOARD;
    return this.http.get<any>(url, { headers }).pipe(
      map(dataList => dataList.data)
    );
  }

  postApplication(application: any) {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_APPLICATION;
    return this.http.post<ApplicationDto>(url, application, { headers });
  }

  putApplication(application_ID: string, application: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.PUT_APPLICATION.replace('{application_id}', application_ID);
    return this.http.put<any>(url, {application_ID, ...application}, { headers });
  }

  getAllNotifications() {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_NOTIFICATIONS}`, {headers})
  }

  deleteNotifications(notificationID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DELETE_NOTIFICATIONS.replace('{notification_ID}', notificationID);
    return this.http.delete<any>(url, { headers });
  }

  getUserById(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_USER}`, {headers})
  }

  getUserRole(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString() );
    return this.http.get<any>(`${this.backendURL + API_URLS.GET_ROLE}`, {headers})
  }

  postComment(application_ID: string, comment: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_COMMENT.replace('{application_ID}', application_ID);
    return this.http.post<any>(url, comment, { headers });
  }

  getCommentsForApplicationById(application_Id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.POST_COMMENT.replace('{application_ID}', application_Id);
    return this.http.get<any>(url, {headers})
  }


  changeStateOfApplication(application_ID: string, state: string, rejectionReason: string | null): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.PUT_UPDATE_STATE.replace('{application_ID}', application_ID);
    return this.http.put<any>(url, {state, rejectionReason}, { headers });
  }

  // kategorien bei zuschuss
  uploadDocuments(application_ID: string, documents: Array<any>): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.POST_DOCUMENTS.replace('{application_ID}', application_ID);
    return this.http.post<any>(url, {dataUpload: documents}, { headers });
  }

  deleteDocuments(document_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DELETE_DOCUMENTS.replace('{document_id}', document_id);
    return this.http.delete<any>(url, { headers });
  }





  //
  getZuschussDocuments(application_ID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.PUT_UPDATE_STATE.replace('{application_ID}', application_ID);
    return this.http.get<any>(url, { headers });
  }




  getContractDocuments(application_ID: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.GET_CONTRACT_BY_APPLICATION_ID.replace('{application_id}', application_ID);
    return this.http.get<any>(url, { headers });
  }

  downloadSelectedDocumentByDocumentId(document_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.cookieService.get('user-token').toString());
    const url = this.backendURL + API_URLS.DOWNLOAD_DOCUMENT_CONTENT.replace('{document_id}', document_id);
    return this.http.get<any>(url, { headers });
  }





  getConfirmations(confirmation_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.GET_CONFIRMATIONS.replace('{confirmation_id}', confirmation_id);
    return this.http.get<any>(url, {headers})
  }



  getStateHistory(application_Id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.GET_STATUS_HISTORY.replace('{application_ID}', application_Id);
    return this.http.get<any>(url, {headers})
  }

  postInternalNotes(application_Id: string, note: object): Observable<any> {
    const headers = new HttpHeaders().set('Authorization',  this.cookieService.get('user-token').toString() );
    const url = this.backendURL + API_URLS.POST_ITERNAL_NOTE.replace('{application_ID}', application_Id);
    return this.http.post<any>(url, note, {headers})
  }
}
