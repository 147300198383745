<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.sponsoringApplicant.title')"></app-wizard-header>

  <div formGroupName="applicant" id="wizard-sponsoring-applicant" class="row">
    <div class="container">

      <mat-form-field class="full-with"  >
        <label class="font-c2">{{t('formFieldMapping.rd_number')}}</label>
        <input  class="readonlyText" [readonly]="true" type="number" matInput [formControlName]="'rd_number'">
        <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.rd_number', form)">{{t('error.required')}}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-with">
        <label class="font-c2">{{t('formFieldMapping.personal_number')}}</label>
        <input type="number" matInput appFormControlError [formControlName]="'personal_number'">
        <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.personal_number', form)">{{t('error.required')}}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-with">
        <label class="font-c2">{{t('formFieldMapping.career_level')}}</label>
        <input matInput [formControlName]="'career_level'">
        <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.career_level', form)">{{t('error.required')}}</mat-error>
      </mat-form-field>

      <div class="col-lg-12 two-element-row">
        <mat-form-field class="col-lg-6 first">
          <label class="font-c2">{{t('formFieldMapping.firstName')}}</label>
          <input matInput [formControlName]="'firstName'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.firstName', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-6 second">
          <label class="font-c2">{{t('formFieldMapping.lastName')}}</label>
          <input matInput [formControlName]="'lastName'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.lastName', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-12 two-element-row">
        <mat-form-field class="col-lg-10">
          <label class="font-c2">{{t('formFieldMapping.street_name')}}</label>
          <input matInput [formControlName]="'street_name'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.street_name', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-2">
          <label class="font-c2">{{t('formFieldMapping.street_number')}}</label>
          <input matInput [formControlName]="'street_number'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.street_number', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-12 two-element-row">
        <mat-form-field class="col-lg-2">
          <label class="font-c2">{{t('formFieldMapping.zip')}}</label>
          <input type="number" matInput [formControlName]="'zip'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.zip', form)">
            {{form.get('applicant.zip')?.hasError('required') ? t('error.required') : t('error.postalCodeInvalid')}}</mat-error>
        </mat-form-field>

        <mat-form-field class="col-lg-10">
          <label class="font-c2">{{t('formFieldMapping.city')}}</label>
          <input matInput [formControlName]="'city'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.city', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="full-with">
        <label class="font-c2">{{t('formFieldMapping.email')}}</label>
        <input class="readonlyText" [readonly]="true"  matInput [formControlName]="'email'">
        <mat-error
          *ngIf="this.validationService.isFieldInvalid('applicant.email', form)">
          {{form.get('applicant.email')?.hasError('required') ? t('error.required') : t('error.mail') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-with">
        <label class="font-c2">{{t('formFieldMapping.telephone')}}</label>
        <input matInput [formControlName]="'telephone'">
        <mat-error *ngIf="this.validationService.isFieldInvalid('applicant.telephone', form)">{{t('error.required')}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isApplicantInvalid(form)"></app-wizard-footer>
</ng-container>
