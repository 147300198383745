<ng-container id="next-steps" *transloco="let t">
  <app-stepper></app-stepper>
  <div class="container">
    <h3 class="text-center">{{t('wizard.next.confirmation')}}</h3>
    <mat-card>
      <div class="timeline">
        <div class="timeline-item" *ngFor="let step of steps; let i = index">
          <div class="timeline-number font-c1">{{ i + 1 }}.</div>
          <div class="timeline-content">
            <p class="font-c1">{{ t('wizard.next.step' + step) }}</p>
          </div>
        </div>
      </div>
    </mat-card>
    <app-hint [hintDescription]="t('wizard.next.hint')"></app-hint>
    <app-text-button [isPrimaryButton]="true" buttonText="Zurück zum Dashboard" (click)="navigateToDashboard()"></app-text-button>
    <div class="helpPart col-lg-12">
      <app-media-box class="helpPart col-md-3 col-sm-3 col-lg-3" [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
    </div>
  </div>

  <ng-template #mediaBoxContent>
    <app-icons [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
    <h5>{{t('dashboard.mediaPart.helpTitle')}}</h5>
    <p class="font-c3">{{t('dashboard.mediaPart.helpDescription')}}</p>
  </ng-template>
</ng-container>


