import {Injectable} from '@angular/core';
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";
import {DataService} from "../../../core/services/data.service";
import {DatePipe} from "@angular/common";
import {translate} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class StateTextService {
  constructor(private dataService: DataService, private datePipe: DatePipe) {}

  returnTextForApplicant(state: string, date?: string): { button: Array<{ text: string, disabled: boolean }>, title: string, content: string } {
    /* Szenario 1 */
    if (state === applicationStateEnum.OPEN) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }

    if(state === applicationStateEnum.CORRECTION_CHECK){
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      }


    }
    if (state === applicationStateEnum.CORRECTION_NEEDED) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false}],
        title: "Eine Korrektur ist erforderlich",
        content: "Bitte korrigieren Sie die markierten Bereiche. Sie erkennen die zu korrigierenden Bereiche anhand der orangefarbenen Umrandung. Zusätzlich hat ihr ERGO Sponsoring-Manager ein Kommentar für Sie hinterlassen, welche Anpassungen sie vornehmen müssen.Klicken Sie auf den Button “Weiter im Prozess” um den Antrag zu korrigieren."
      };
    }
    if (state === applicationStateEnum.CONTRACT_CHECK) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten.  Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.CHECK_RD) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit vom RD-Leiter geprüft",
        content: "Der ERGO Sponsoring-Manager hat Ihre Daten akzeptiert und Ihren Antrag an den zuständigen RD-Leiter weitergereicht. Ihre RD muss nun das Sponsoring empfehlen.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.CHECK_VKF) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Ihre RD hat die Sponsoring-Maßnahme empfohlen. Ihr ERGO Sponsoring-Manager muss nun über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme entscheiden.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    if (state === applicationStateEnum.APPROVED_ADD_CONTRACT) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false}],
        title: "Vertrag einreichen",
        content: "Ihr Sponsoring-Antrag wurde genehmigt.Um die Genehmigung abzuschließen sind noch weitere Schritte erforderlich.Klicken Sie auf den Button “Weiter im Prozess” um den Vertrag einzureichen."
      }
    }
    if (state === applicationStateEnum.CONTRACT_ADDED) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Vertrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
        content: "Ein ERGO Sponsoring-Manager wird den eingereichten Vertrag auf Richtigkeit und Vollständigkeit prüfen. Wenn eine Korrektur erforderlich sein sollte, werden Sie darüber benachrichtigt.Nach erfolgreicher Überprüfung können Sie Dateien für den Zuschussprozess einreichen. Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      }
    }
    if (state === applicationStateEnum.DONE) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde vertraglich genehmigt",
        content: "Sie dürfen das Sponsoring nun durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.REJECTED_VKF) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde vom ERGO Sponsoring-Manager abgelehnt",
        content: "Sie dürfen das Sponsoring nicht durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    if (state === applicationStateEnum.REJECTED_RD) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde von Ihrem RD-Leiter nicht empfohlen",
        content: "Aufgrund dessen müssen wir Ihre Sponsoring-Maßnahme ablehnen.Begründung:Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.Sie dürfen das Sponsoring nicht durchführen. Der Prozess ist hiermit abgeschlossen."
      }
    }
    /* Szenario2 */
    if (state === applicationStateEnum.CHECK_GLVKF) {
      return {
        button: [{text: "Weiter im Prozess", disabled: true}],
        title: "Ihr Antrag wird derzeit vom GLVKF geprüft",
        content: "Ihre RD hat die Sponsoring-Maßnahme empfohlen. Der GLVKF muss nun über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme entscheiden.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
      };
    }
    // todo Kommentar von VKF?
    if (state === applicationStateEnum.REJECTED_GLVKF) {
      return {
        button: [],
        title: "Ihre Sponsoring-Maßnahme wurde vom ERGO Sponsoring-Manager abgelehnt",
        content: "Begründüng:."
      };
    }

    // szenario 3

    if (state === applicationStateEnum.ZUSCHUSS) {
      return {
        button: [{text: "Weiter im Prozess", disabled: false}],
        title: "Zuschuss-Dokumente hochladen",
        content: "hre Sponsoring-Maßnahme wurde vertraglich genehmigt. Sie dürfen das Sponsoring nun durchführen. Zudem haben Sie einen Zuschuss beantragt. Damit Ihr Zuschuss freigegeben werden kann, müssen Sie noch Zuschuss-Dokumente hochladen.Klicken Sie auf den Button “Weiter im Prozess” um Zuschuss-Dokumente hochzuladen."
      };
    }

    /* todo ergänzen der status

    Korrektur erforderlich nach Zuschuss - anderer Text
    Korrektur erforderlich nach Vertrag hinzufügen anderer Text
    Zuschuss In Prüfung anderer text
    if(state === applicationStateEnum.CORRECTION_NEEDED_CONTRACT){
      return {
        button: [{text:"Weiter im Prozess", disabled: false}],
        title:"Eine Korrektur ist erforderlich",
        content: "Ihr eingereichter Vertrag ist fehlerhaft. Bitte reichen Sie den Vertrag erneut ein. Ihr ERGO Sponsoring-Manager hat ein Kommentar für Sie hinterlassen, welche Anpassungen sie vornehmen müssen.Klicken Sie auf den Button “Weiter im Prozess” um den Vertrag zu korrigieren."
     */
    return {button: [], title: '', content: ''}
  }
  returnTextForBox(state: string, date?: string): { button: Array<{ text: string, disabled: boolean }>, title: string, content: string } {
    switch (state) {
      case applicationStateEnum.OPEN:
        return {
          button: [{ text: "Weiter im Prozess", disabled: !!UserRoleEnum.APPLICANT }],
          title: "Ihr Antrag wird derzeit von einem ERGO Sponsoring-Manager geprüft",
          content: "Sie erhalten eine entsprechende Benachrichtigung über das Prüfergebnis. Falls Anpassungen erforderlich sind, wird der Antrag zur Korrektur zurückgegeben, und Sie können ihn erneut bearbeiten. Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };

      case applicationStateEnum.CORRECTION_NEEDED:
        return {
          button: [{ text: "Weiter im Prozess", disabled: false }],
          title: "Eine Korrektur ist erforderlich",
          content: "Bitte korrigieren Sie die markierten Bereiche. Sie erkennen die zu korrigierenden Bereiche anhand der orangefarbenen Umrandung. Zusätzlich hat ihr ERGO Sponsoring-Manager ein Kommentar für Sie hinterlassen, welche Anpassungen sie vornehmen müssen. Klicken Sie auf den Button “Weiter im Prozess” um den Antrag zu korrigieren."
        };

      case applicationStateEnum.NEU:
        return {
          button: [{ text: "Daten prüfen", disabled: false }],
          title: "Neuer Sponsoring-Antrag eingegangen",
          content: `Bitte prüfen Sie die Daten des neu eingegangenen Antrags vom ${this.datePipe.transform(date, 'dd.MM.yyyy')}. Klicken Sie auf den Button 'Daten prüfen'.`
        };

      case applicationStateEnum.CORRECTION_VP:
        return {
          button: [{ text: "Weiter im Prozess", disabled: true }],
          title: "Der Antrag wird derzeit vom Antragsteller korrigiert",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Sie die Daten erneut prüfen können. Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };

      case applicationStateEnum.CORRECTION_CHECK:
        return {
          button: [{ text: "Daten prüfen", disabled: false }],
          title: "Der Antrag ist zur Korrektur bereit",
          content: "Bitte prüfen Sie die Daten. Klicken Sie auf den Button 'Daten prüfen'."
        };

      case applicationStateEnum.CHECK_RD:
        return {
          button: [{ text: "Weiter im Prozess", disabled: true }],
          title: 'Der Antrag wird derzeit vom RD-Leiter geprüft',
          content: 'Sie erhalten eine entsprechende Benachrichtigung wenn der RD-Leiter eine Empfehlung ausgesprochen hat.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren.'
        };

      case applicationStateEnum.CHECK_VKF:
        return {
          button: [{ text: "Antrag ablehnen", disabled: false }, { text: "Antrag genehmigen", disabled: false }],
          title: 'Eine Empfehlung der RD liegt vor',
          content: 'Die RD hat die Sponsoring-Maßnahme empfohlen. Bitte prüfen Sie die Empfehlung und entscheiden Sie über die Genehmigung oder Ablehnung der Sponsoring-Maßnahme.Wenn Sie auf einen der Buttons klicken, wird die Aktion direkt ausgeführt.'
        };

      case applicationStateEnum.CHECK_GLVKF:
        return {
          button: [{ text: "Weiter im Prozess", disabled: false }],
          title: "Der Antrag wird derzeit vom GLVKF geprüft",
          content: "Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };

      case applicationStateEnum.APPROVAL_NO_CONTRACT:
        return {
          button: [{ text: "Weiter im Prozess", disabled: true }],
          title: 'Warten auf Einreichung des Vertrags',
          content: 'Sie erhalten eine entsprechende Benachrichtigung wenn der Antragsteller den Vertrag hochgeladen hatt.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren.'
        };

      case applicationStateEnum.CONTRACT_CHECK:
        return {
          button: [{ text: "Weiter im Prozess", disabled: false }],
          title: "Der Vertrag ist zur Überprüfung bereit",
          content: "Bitte prüfen Sie den eingereichten Vertrag auf Richtigkeit und Vollständigkeit.Klicken Sie auf den Button “Weiter im Prozess”."
        };

      case applicationStateEnum.ZUSCHUSS:
        return {
          button: [{ text: "Weiter im Prozess", disabled: false }],
          title: "Der Antragsteller lädt derzeit Zuschuss-Dokumente hoch",
          content: "Sie erhalten eine entsprechende Benachrichtigung wenn Zuschuss-Dokumente vorliegen und Sie diese prüfen können.Wenn der untenstehende Button aktiv ist, können Sie mit dem nächsten Prozessschritte fortfahren."
        };

      case applicationStateEnum.DONE:
        return {
          button: [],
          title: "Die Sponsoring-Maßnahme wurde vertraglich genehmigt",
          content: "Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen."
        };

      case applicationStateEnum.REJECTED_VKF:
        return {
          button: [],
          title: "Sie haben die Sponsoring-Maßnahme abgelehnt",
          content: "Es gibt nichts weiter für Sie zu tun. Der Prozess ist hiermit abgeschlossen."
        };

      case applicationStateEnum.REJECTED_RD:
        return {
          button: [{ text: "Antrag ablehnen", disabled: false }],
          title: "Die Sponsoring-Maßnahme wurde vom RD-Leiter nicht empfohlen",
          content: "Aufgrund dessen müssen Sie Sponsoring-Maßnahme ablehnen. Wenn Sie auf den Button “Antrag ablehnen” klicken, wird die Sponsoring-Maßnahme direkt abgelehnt."
        };

      default:
        return { button: [], title: '', content: '' };
    }
  }

}
