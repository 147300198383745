<ng-container [formGroup]="form" *transloco="let t">
  <div class="dialog-header">
    <h3>{{ t('wizardTitles.' + groupName) }}</h3>
    <mat-icon svgIcon="close-icon" (click)="closeDialog()"></mat-icon>
  </div>
  <div class="dialog-content container">
    <div class="row">
    <ng-container *ngFor="let key of keyList">
      <ng-container [ngSwitch]="key.data.value | fileTypePipe">

        <ng-container *ngSwitchCase="'string'">
          <div class="col-lg-6">
            <mat-form-field class="col-lg-6 full-width"  appearance="fill">
              <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
              <input [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" matInput [formControl]="key.data"/>
              <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'number'">
          <div class="col-lg-6">
            <mat-form-field class="col-lg-6 full-width"  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" appearance="fill">
              <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
              <input  [ngClass]="isFieldReadonly(key.field) ? 'readonlyText' : ''" [readonly]="isFieldReadonly(key.field)" type="number" matInput [formControl]="key.data"/>
              <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
          <div class="radio-button-group col-lg-6">
            <label class="font-c3 radio-label">{{ t('formFieldMapping.' + key.field) }}</label>
            <mat-radio-group class="radio-button" [formControl]="key.data" (change)="changeValue($event)">
              <mat-radio-button [value]="true"><p>{{t('common.yes')}}</p></mat-radio-button>
              <mat-radio-button [value]="false"><p>{{t('common.no')}}</p></mat-radio-button>
              <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
            </mat-radio-group>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
          <div class="col-lg-6">
            <mat-form-field class="col-lg-6 full-width" appearance="fill">
              <label class="font-c3">{{ t('formFieldMapping.' + key.field) }}</label>
              <input matInput [matDatepicker]="picker" [formControl]="key.data"/>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="key.data.errors">{{ t('error.' + getFormControlErrors(key.data))}}</mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div>
      <div class="button-group col-lg-12">
        <app-text-button  [isButtonReadOnly]="!isFormValid()" [isPrimaryButton]="true" buttonText="Übernehmen" (click)="saveFormValues()"></app-text-button>
      </div>
    </div>
  </div>

  </div>
</ng-container>
