import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormService } from "../../../services/form-service/form.service";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslocoDirective } from "@ngneat/transloco";
import { DatePipe, NgForOf, NgIf } from "@angular/common";

@Component({
  selector: 'app-second-year',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoDirective,
    NgIf,
    NgForOf,
    DatePipe
  ],
  templateUrl: './second-year.component.html',
  styleUrls: ['./second-year.component.scss']
})
export class SecondYearComponent implements OnChanges, OnInit {
  @Input() isEdited = false;
  @Input() data: any;
  form: FormGroup;
  isFullPayedSecondYear: boolean = false;

  constructor(private formService: FormService) {
    this.form = this.formService.buildForm();
    if (!this.data) {
      this.formService.initializeForm(this.form);
    }
  }

  ngOnInit() {
    this.updatePaymentStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes || this.isEdited) {
      this.updatePaymentStatus();
      if (this.isEdited && !this.data) {
        this.formService.initializeForm(this.form);
      }
    }
  }

  private updatePaymentStatus() {
    this.isFullPayedSecondYear = this.data
      ? this.data.financialAssistance.secondYear.fullPayed === true
      : this.form.get('financialAssistance.secondYear.fullPayed')?.value === true;
  }

  getValue(path: string): any {
    if (this.data && this.data[path] !== undefined) {
      return this.data[path];
    } else {
      return this.form.get(path)?.value;
    }
  }
}
