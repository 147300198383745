import {Component, Input, OnInit} from '@angular/core';
import {IconsComponent} from "../icons/icons.component";
import {TranslocoModule} from "@ngneat/transloco";
import {DataService} from "../../../core/services/data.service";
import {DatePipe} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {applicationStateEnum} from "../../../api/enum/stateEnum";

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    IconsComponent,
    TranslocoModule,
    DatePipe,
    MatCard,
    MatButton
  ],
  templateUrl: './alert.html',
  styleUrl: './alert.scss'
})
export class Alert implements OnInit{
  //allApplications = this.dataService.getAllApplications().pipe(map(application => application));
 // lastApplication: ApplicationDto = {} as ApplicationDto;
  @Input() notification: any;

  constructor(private dataService: DataService) {}
  ngOnInit() {
  }
}
