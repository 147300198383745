import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatFormField, MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, Validators,
} from "@angular/forms";
import {JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatInput, MatInputModule} from "@angular/material/input";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatNativeDateModule, MatOption} from "@angular/material/core";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {TranslocoDirective} from "@ngneat/transloco";
import {FormService} from "../../services/form-service/form.service";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {TextButtonComponent} from "../button/text-button/text-button.component";
import {MatIcon} from "@angular/material/icon";
import {IconTextButtonComponent} from "../button/icon-text-button/icon-text-button.component";
import {MatSelect} from "@angular/material/select";
import {FinancialAssistance} from "../../../pages/sponsoring-wizard/financial-assistance/financial-assistance";
import {FinancialAssistanceService} from "../../services/financialAssistance/financialAssistance.service";
import {ValidationService} from "../../services/validation-service";

@Component({
  selector: 'app-edit-form-dialog',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    NgForOf,
    MatButton,
    MatInput,
    NgIf,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioButton,
    MatRadioGroup,
    TranslocoDirective,
    FileTypePipe,
    NgSwitch,
    NgSwitchCase,
    TextButtonComponent,
    MatIcon,
    IconTextButtonComponent,
    MatOption,
    MatSelect,
    JsonPipe,
    NgClass,
    FinancialAssistance,
  ],
  providers: [FileTypePipe],
  templateUrl: './edit-form-dialog.component.html',
  styleUrl: './edit-form-dialog.component.scss'
})
export class EditFormDialogComponent implements OnInit {
  field = '';
  rates: number[] = [2, 3, 4];
  keyList: Array<{ data: FormControl, field: string, type: string }> = [];

  get services(): FormArray {
    return this.form.get('entitlements.services') as FormArray;
  }

  get activeGroup(): FormGroup {
    return this.form.get(this.groupName) as FormGroup;
  }

  form = this.formService.buildForm();
  groupName = '';

  constructor(public formService: FormService,
              public fileTypePipe: FileTypePipe,
              public validationService: ValidationService,
              public financialAssistanceService: FinancialAssistanceService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<EditFormDialogComponent>,
              public fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.groupName = data.name;
  }

  ngOnInit() {
    if(this.data.serverData){
      this.form = this.formService.initializeFormFromData(this.data.serverData);
    } else {
      this.formService.initializeForm(this.form);
    }

    Object.keys(this.activeGroup.controls).forEach((field) => {
      const control = this.activeGroup.get(field);
      if (control) {
        this.getFirstFormControl(control, field);
      }
    });
    this.cdr.detectChanges();
  }


  getFirstFormControl(data: any, field?: string): any | null {
    if (data instanceof FormControl) {
      const type = this.fileTypePipe.transform(data.value);
      if (field != null) {
        this.keyList.push({data, field, type});
      }
      return data;
    } else if (data instanceof FormGroup) {
      for (const key of Object.keys(data.controls)) {
        this.getFirstFormControl(data.controls[key], field + '.' + key);
      }
    } else if (data instanceof FormArray) {
      for (let i = 0; i < data.controls.length; i++) {
        this.getFirstFormControl(data.controls[i], field + '[' + i + ']');
      }
    }
  }

  createNewServiceForm(value = ''): FormControl {
    const servicesCount = this.services.length;
    const validators = servicesCount < 2 ? [Validators.required, Validators.minLength(10)] : [];
    return this.fb.control(value, validators);
  }

  addNewLeistung(): void {
    const newServiceControl = this.createNewServiceForm('');
    this.services.push(newServiceControl);

    const field = `services.${this.services.length - 1}`;
    const type = this.fileTypePipe.transform(newServiceControl.value);
    const newKeyEntry = {data: newServiceControl, field, type};

    // diese Logik dafür, dass die Elemente an der richtigen Stelle angezeigt werden //
    const lastServiceIndex = this.keyList
      .map(key => key.field)
      .reduce((lastIndex, currentField, index) => {
        return currentField?.startsWith('services') ? index : lastIndex;
      }, -1);
    if (lastServiceIndex >= 0) {
      this.keyList.splice(lastServiceIndex + 1, 0, newKeyEntry);
    } else {
      this.keyList.push(newKeyEntry);
    }
  }

  isFieldReadonly(field: string): boolean {
    const notEditableControls = ['personal_number', 'email'];
    return notEditableControls.includes(field);
  }

  isFormValid(): boolean {
    let isValid = true;
    this.keyList.forEach((listelement) => {
      if (!listelement.data.valid) {
        isValid = false;
      }
    });
    return isValid;
  }

  changeValue(event: MatRadioChange) {
    if (event) {
      this.formService.setFormData(this.form);
    }
  }

  closeDialog(form?: FormGroup) {
    form ? this.dialogRef.close(form) : this.dialogRef.close();
  }

  saveFormValues() {
    this.formService.setFormData(this.form);
    this.closeDialog(this.form);
  }

  onSaveInFinancialAssistance(form: any): void {
    this.form = form;
    const invalidControls = this.validationService.isFinancialAssistanceInvalid(form)?.some(control => control?.invalid);
    if (!invalidControls) {
      this.closeDialog(form);
    } else {
      this.form.markAllAsTouched();
    }
  }

  getFormControlErrors(control: FormControl, field?: string): string | null {
    if (control && field) {
      if (field.startsWith('services') && field !== 'services[0]' && field !== 'services[1]') {
         control.setErrors(null);
         this.cdr.detectChanges();
         return null;
      }
      if (control.errors) {
        const errors = control.errors;
        for (const errorKey in errors) {
          if (errors.hasOwnProperty(errorKey)) {
            return errorKey;
          }
        }
      }
    }
    return null;
  }

}
