<div class="col-lg-12" *ngIf="selectedFiles">
  <div *ngFor="let selectedFile of allFiles">
    <div class="displayFile">
      <app-icons class="icon" [iconName]="'mountain'"></app-icons>
      <div class="text">
        <p class="font-c2">{{ selectedFile.name }}</p>
        <p class="font-c2 description">
          {{ '.'+selectedFile.type.split('/')[1]! }} |
          {{ selectedFile.size | bytesToSize }} |
          {{ (selectedFile.lastModified | date:'dd.MM.yyyy') + ' Uhr'}}
        </p>
      </div>
    </div>
  </div>
</div>
