<ng-container *ngIf="buttonSecondary else defaultButton">
    <button mat-button>
      <div class="secondaryIcon" *ngIf="!buttonLeft else buttonOrientationLeft ">
        <p class="button-font {{textColor}}">{{buttonText}}</p>
        <mat-icon class="{{iconSize}} {{textColor}}" svgIcon="{{iconName}}"></mat-icon>
      </div>
      <ng-template #buttonOrientationLeft>
        <div class="secondaryIcon">
          <mat-icon class="{{iconSize}} {{textColor}}" svgIcon="{{iconName}}"></mat-icon>
          <p class=" font-c2-link {{textColor}}">{{buttonText}}</p>
        </div>
      </ng-template>
    </button>
</ng-container>

<ng-template #defaultButton>
  <button class="{{class}} ergo-red" mat-fab extended disabled="{{isDisabled}}">
    <div class="buttonContent" *ngIf="!buttonLeft else buttonOrientationLeft ">
      <mat-icon class="{{iconSize}} {{textColor}}" svgIcon="{{iconName}}"></mat-icon>
      <p class="button-font">{{buttonText}}</p>
    </div>
    <ng-template #buttonOrientationLeft>
      <div class="buttonContent">
        <p class="button-font">{{buttonText}}</p>
        <mat-icon class="{{iconSize}} {{textColor}}" svgIcon="{{iconName}}"></mat-icon>
      </div>
    </ng-template>
  </button>
</ng-template>


