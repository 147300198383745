import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton, MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {NgClass, NgIf} from "@angular/common";
import {FormService} from "../../../services/form-service/form.service";

@Component({
  selector: 'app-text-button',
  standalone: true,
  imports: [
    MatFabButton,
    MatButton,
    MatIcon,
    NgIf,
    NgClass
  ],
  templateUrl: './text-button.component.html',
  styleUrl: './text-button.component.scss'
})
export class TextButtonComponent {
  @Input() isPrimaryButton = false;
  @Input() buttonText = '';
  @Input() isButtonReadOnly = false;

  @Output()
  iconClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(public formService: FormService) {
  }

  clicked(): void {
    this.iconClicked.emit();
  }
}
