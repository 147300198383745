import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'fileTypePipe'
})
export class FileTypePipe implements PipeTransform {

  private fileTypeValue(value: any): string {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,6})?Z)$/;

    if (typeof value === 'string' && iso8601Regex.test(value)) {
      return 'date';
    }
    else if (typeof value === 'string' && !iso8601Regex.test(value)) {
      return 'string';
    }
    else if (typeof value === 'number') {
      return 'number';
    }
    else if (typeof value === 'boolean') {
      return 'boolean';
    }
    else if(Array.isArray(value)){
      return 'array';
    }
    else if (typeof value === 'object') {
      return 'object';
    }
    return ''
  }

  transform(value: any): string {
    return this.fileTypeValue(value);
  }
}
