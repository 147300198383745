import { Component } from '@angular/core';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {WizardService} from "../../services/wizard-service/wizard.service";

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [
    MatProgressBarModule
  ],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss'
})
export class StepperComponent {
  constructor(public wizardService: WizardService) {
    this.wizardService.updateCurrentPage();
  }

  get progressValue() {
    return (this.wizardService.currentPage / this.wizardService.allPages) * 100;
  }

}
