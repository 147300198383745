<table mat-table id="application-overview-table" [dataSource]="dataSource">
  <ng-container matColumnDef="application">
    <th mat-header-cell *matHeaderCellDef><h4>Antrag</h4></th>
    <td mat-cell *matCellDef="let element"><p class="font-c2">{{element.name}}</p></td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef><h4>Datum des Einreichens</h4></th>
    <td  mat-cell *matCellDef="let element"><p class="font-c2">{{element?.created_at | date:'dd.MM.yyyy'}}</p></td>
  </ng-container>
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef><h4>Status</h4></th>
    <td mat-cell *matCellDef="let element"><p class="font-c2">
      <app-application-state-component class="state" (click)="editApplication(element)" [state]="element.status"></app-application-state-component>
    </p></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
