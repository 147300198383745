<ng-container *transloco="let t">
  <div class="dialog-header">
    <h3>Grund der Ablehnung</h3>
    <mat-icon svgIcon="close-icon" (click)="closeDialog()"></mat-icon>
  </div>

  <div class="dialog-content container">
    <div class="row">
        <textarea [(ngModel)]="rejectionReason" class="element-space"></textarea>
      <div>
        <div class="button-group col-lg-12">
          <app-text-button  [isPrimaryButton]="true" buttonText="Vertrag ablehnen"></app-text-button>
        </div>
      </div>
    </div>

  </div>
</ng-container>


