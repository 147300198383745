import {Routes} from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {SponsoringDuration} from "./pages/sponsoring-wizard/sponsoring-duration/sponsoring-duration";
import {SponsoringValue} from "./pages/sponsoring-wizard/sponsoring-value/sponsoring-value";
import {SponsoringStartDate} from "./pages/sponsoring-wizard/sponsoring-start-date/sponsoring-start-date";
import {SponsoringEndDate} from "./pages/sponsoring-wizard/sponsoring-end-date/sponsoring-end-date";
import {Confirmation} from "./pages/sponsoring-wizard/confirmation/confirmation";
import {ApplicantInformation} from "./pages/sponsoring-wizard/applicant-information/applicant-information";
import {SponsoredInformation} from "./pages/sponsoring-wizard/sponsored-information/sponsored-information";
import {FinancialAssistance} from "./pages/sponsoring-wizard/financial-assistance/financial-assistance";
import {Entitlements} from "./pages/sponsoring-wizard/entitlements/entitlements";
import {Consent} from "./pages/sponsoring-wizard/consent/consent";
import {SponsoringDataUpload} from "./pages/sponsoring-wizard/sponsoring-data-upload/sponsoring-data-upload";
import {Conclusion} from "./pages/sponsoring-wizard/conclusion/conclusion";
import {NextStepsComponent} from "./pages/sponsoring-wizard/next-steps/next-steps.component";
import {LoginComponent} from "./pages/login/login.component";
import {
  VkfDetailoverviewComponent
} from "./pages/detail-overview-pages/vkf-detailoverview/vkf-detailoverview.component";
import {
  DetailOverviewPagesComponentComponent
} from "./pages/detail-overview-pages/detail-overview-pages-component/detail-overview-pages-component.component";

export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'duration', component: SponsoringDuration},
  { path: 'sponsoring-value', component: SponsoringValue},
  { path: 'start-date', component: SponsoringStartDate},
  { path: 'end-date', component: SponsoringEndDate},
  { path: 'confirmation', component: Confirmation},
  { path: 'applicant', component: ApplicantInformation},

  { path: 'sponsored', component: SponsoredInformation},
  { path: 'financial-assistance', component: FinancialAssistance},
  { path: 'entitlements', component: Entitlements},
  { path: 'consent', component: Consent},
  { path: 'file-upload', component: SponsoringDataUpload},
  { path: 'conclusion', component: Conclusion},
  { path: 'next-steps', component: NextStepsComponent},

  { path: 'detailview', component: DetailOverviewPagesComponentComponent},

];
