import { Component } from '@angular/core';
import {FormService} from "../../../shared/services/form-service/form.service";
import {TranslocoDirective} from "@ngneat/transloco";
import {MatCard} from "@angular/material/card";
import {NgForOf} from "@angular/common";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {Router} from "@angular/router";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";

@Component({
  selector: 'app-next-steps',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatCard,
    NgForOf,
    HintComponent,
    TextButtonComponent,
    MediaBoxComponent,
    IconsComponent,
    StepperComponent
  ],
  templateUrl: './next-steps.component.html',
  styleUrl: './next-steps.component.scss'
})
export class NextStepsComponent{
  form = this.formService.buildForm();
  steps = [1, 2, 3, 4, 5, 6];

  constructor(private formService: FormService, private router: Router) {
    this.formService.initializeForm(this.form);
  }

  navigateToDashboard() {
    localStorage.removeItem('form-data');
    this.router.navigate(['/dashboard']);
  }
}
