<ng-container *transloco="let t">
  <div class="row wizard-header" id="wizard-header">
    <div class="container">
      <ng-container *ngIf="currentComponent === 'sponsoring-value' else defaultHeader">
        <div class="element-space">
          <app-icon-text-button [buttonSecondary]="true"
                                [iconName]="'arrow-left'"
                                [iconSize]="'small'"
                                [buttonText]="t('common.previous')"
                                [textColor]="'red'"
                                [buttonLeft]="true"
                                (click)="this.wizardService.navigateToPreviousPage()"
          ></app-icon-text-button>
        </div>
        <app-hint [hintDescription]="t('wizard.sponsoringValue.hints.download')"></app-hint>
        <app-hint [hintDescription]="t('wizard.sponsoringValue.hints.required')"></app-hint>
        <p class="font-c1 text-center">{{t('wizard.stepper', {current: this.wizardService.currentPage, all: this.wizardService.allPages})}}</p>
        <h1 class="text-center sectionSpaceDefault">{{title}}</h1>
        <h3 class="text-center">{{subtitle}}</h3>
        <p *ngIf="viewMode" class="text-center">{{status}}</p>
      </ng-container>


      <ng-template #defaultHeader>
        <div class="buttonGroup sectionSpaceDefault">
          <app-icon-text-button *ngIf="!viewMode"
                                [buttonSecondary]="true"
                                [iconName]="'arrow-left'"
                                [iconSize]="'small'"
                                [buttonText]="t('common.previous')"
                                [textColor]="'red'"
                                [buttonLeft]="true"
                                (click)="this.wizardService.navigateToPreviousPage()"
          ></app-icon-text-button>
        </div>
        <p  *ngIf="!viewMode" class="font-c1 text-center">{{t('wizard.stepper', {current: this.wizardService.currentPage, all: this.wizardService.allPages})}}</p>
        <h1 class="text-center">{{title}}</h1>
        <h3 class="text-center">{{subtitle}}</h3>
        <div class="text-center state" *ngIf="viewMode">
          <p class="lead">Status:</p>
          <app-application-state-component  [state]="status"></app-application-state-component>
        </div>

      </ng-template>
    </div>
  </div>
</ng-container>
