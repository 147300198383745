import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormService} from "../../../services/form-service/form.service";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslocoDirective} from "@ngneat/transloco";
import {DatePipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-first-year',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoDirective,
    NgIf,
    NgForOf,
    DatePipe
  ],
  templateUrl: './first-year.component.html',
  styleUrl: './first-year.component.scss'
})
export class FirstYearComponent implements OnChanges, OnInit{
  @Input() isEdited = false;
  @Input() data: any;

  form = this.formService.buildForm();
  isSponsoringTwoYears = this.form.get('sponsoringValue.sponsoringDuration')?.value !== 1;

  constructor(private formService: FormService) {}

  ngOnInit() {
    if(!this.data){
      this.formService.initializeForm(this.form);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.isEdited){
      this.formService.initializeForm(this.form);
    }
  }
}
