import { Component } from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormArray, FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {MatError} from "@angular/material/form-field";
import {NgClass, NgIf} from "@angular/common";
import {ValidationService} from "../../../shared/services/validation-service";

@Component({
  selector: 'app-wizard-sponsoring-consent',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    WizardFooterComponent,
    MatCheckbox,
    ReactiveFormsModule,
    StepperComponent,
    MatError,
    NgIf,
    NgClass
  ],
  templateUrl: './consent.html',
  styleUrl: './consent.scss'
})
export class Consent {
  form = this.formService.buildForm();


  constructor(public wizardService: WizardService,
              public validationService: ValidationService,
              public formService: FormService,private fb: FormBuilder) {
    this.formService.initializeForm(this.form);
  }

  checkboxError(controlName: string): string {
    const formControl = this.form.get('consent.' + controlName);
    if(formControl?.touched && formControl?.hasError('mustBeTrue')) {
      return 'error'
    }
    return ''
  }

}
