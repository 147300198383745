import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {isObject, TranslocoDirective} from "@ngneat/transloco";
import {FormService} from "../../services/form-service/form.service";
import {MatIcon} from "@angular/material/icon";
import {EditFormDialogComponent} from "../edit-form-dialog/edit-form-dialog.component";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {MatDialog} from "@angular/material/dialog";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {FirstYearComponent} from "./first-year/first-year.component";
import {SecondYearComponent} from "./second-year/second-year.component";
import {StateTextService} from "../../services/state-text-service/state-text.service";

@Component({
  selector: 'app-financial-assistance-key-value-component',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardTitle,
    NgForOf,
    TranslocoDirective,
    NgIf,
    MatIcon,
    ReactiveFormsModule,
    FirstYearComponent,
    SecondYearComponent,
    DatePipe
  ],
  providers: [FileTypePipe],
  templateUrl: './financial-assistance-key-value-component.html',
  styleUrl: './financial-assistance-key-value-component.scss'
})

export class FinancialAssistanceKeyValueComponent {
  @Input() isEditable = false;
  @Input() data: any;

  isEdited = false;
  form = this.formService.buildForm();

  constructor(public formService: FormService ,
              public dialog: MatDialog,
              public stateTextService: StateTextService
  ) {
  }

  getValue(fieldName: string): any {
    if (this.data && this.data[fieldName] !== undefined) {
      return this.data[fieldName];
    } else {
      return this.form.get(fieldName)?.value;
    }
  }

  openEditDialog() {
    this.isEdited = false;
    const dialogRef = this.dialog.open(EditFormDialogComponent, {
      width: '920px',
      height:'75vh',
      data: {name: 'financialAssistance'}
    });
    dialogRef.afterClosed().subscribe( (result) =>  {
      if(result){
        this.form = result;
        this.isEdited=true;
      }
    });
  }
}
