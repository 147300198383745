import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {MatInput, MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";

import {
  MatDatepickerModule
} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {FormService} from "../../../shared/services/form-service/form.service";
import {ZuschussComponent} from "./zuschuss/zuschuss.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {ValidationService} from "../../../shared/services/validation-service";
import {DynamicFormFieldComponent} from "../../../shared/components/dynamic-form-field/dynamic-form-field.component";
import {FileTypePipe} from "../../../shared/pipes/fileTypePipe";
import {MatCard, MatCardContent, MatCardTitle} from "@angular/material/card";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Component({
  selector: 'app-wizard-sponsoring-financial-assistance',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoModule,
    WizardHeaderComponent,
    FormsModule,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    HintComponent,
    MatInput,
    MatOption,
    NgForOf,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    ZuschussComponent,
    WizardFooterComponent,
    StepperComponent,
    DynamicFormFieldComponent,
    FileTypePipe,
    DynamicFormFieldComponent,
    MatCard,
    MatCardTitle,
    MatCardContent
  ],
  templateUrl: './financial-assistance.html',
  styleUrl: './financial-assistance.scss'
})

export class FinancialAssistance {
  @Input() editMode = false;
  @Output() financialAssistanceUpdated: EventEmitter<any> = new EventEmitter<any>();

  form = this.formService.buildForm();
  rates: number[] = [2, 3, 4];


  get financialAssistance(): FormGroup {
    return this.form.get('financialAssistance') as FormGroup;
  }

  get listOfRatesFirstYear(): FormArray {
    return this.form.get('financialAssistance.firstYear.rates') as FormArray;
  }

  get listOfRatesSecondYear(): FormArray {
    return this.form.get('financialAssistance.secondYear.rates') as FormArray;
  }


  constructor(public wizardService: WizardService,
              private fb: FormBuilder,
              public formService: FormService,
              public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
  }

  changePaymentMethod(fullPayed: MatRadioChange, formGroup: string, year: number): void {
    this.form.get(`financialAssistance.${formGroup}.fullPayed`)?.setValue(fullPayed.value);

    if (year === 1 || formGroup === 'firstYear') {
      this.clearRates(this.listOfRatesFirstYear);
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(null);
      this.addRatesForFirstYear(1);
    } else if (formGroup === 'secondYear') {
      this.clearRates(this.listOfRatesSecondYear);
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(null);
      this.addRatesForSecondYear(1);
    }
    this.formService.setFormData(this.form);
  }

  getSponsoringValue(year: string): number | null {
    const taxFree = this.form.get('sponsoringValue.taxFree')?.value

    if (year === 'firstYear') {
      return taxFree ? this.form.get('sponsoringValue.sponsoringValueNetAmountFirstYear')?.value : this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.value;
    } else if (year === 'secondYear') {
      return taxFree ? this.form.get('sponsoringValue.sponsoringValueNetAmountSecondYear')?.value : this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.value;
    }
    return null
  }


  onSelectRate(selectedRate: number, formGroup: string): void {
    if(formGroup === 'firstYear') {
      this.form.get('financialAssistance.firstYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForFirstYear(selectedRate)
    }
    if(formGroup === 'secondYear') {
      this.form.get('financialAssistance.secondYear.rateAmount')?.setValue(selectedRate);
      this.addRatesForSecondYear(selectedRate);
    }
    this.formService.setFormData(this.form);
  }

  changeRepetition(event: MatRadioChange): void {
    if (!event) return;
    const repetitionYearControl = this.form.get('financialAssistance.repetitionYear');

    if (event.value === false) {
      repetitionYearControl?.setValue(null);
      repetitionYearControl?.clearValidators();
    } else {
      repetitionYearControl?.setValidators(Validators.required);
    }
    repetitionYearControl?.updateValueAndValidity();
    this.formService.setFormData(this.form);
    this.disableFormControl();
  }


  changeZuschuss(event: MatRadioChange) {
    if(event){
      this.formService.setFormData(this.form);
    }
  }

  saveInEditMode(form: any): void {
    this.formService.setFormData(form);
    this.financialAssistanceUpdated.emit(form);
  }

  private addRatesForFirstYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.firstYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesFirstYear);

    if (numberOfRates === 1) {
      this.listOfRatesFirstYear.push(this.fb.group({
        date: this.fb.control(null, [Validators.required]),
      }));
    } else {
      for (let i = 0; i < rateControl; i++) {
        this.listOfRatesFirstYear.push(this.formService.createNewRateForm(i));
      }
    }
  }


  private addRatesForSecondYear(numberOfRates?: number): void {
    const rateControl = numberOfRates !== undefined ? numberOfRates : this.form.get('financialAssistance.secondYear.rateAmount')?.value;
    this.clearRates(this.listOfRatesSecondYear);

    if(this.form.get('sponsoringValue.sponsoringDuration')?.value === 2){
      if (numberOfRates === 1) {
        this.listOfRatesSecondYear.push(this.fb.group({
          date: this.fb.control(null, [Validators.required]),
        }));
      } else {
        for (let i = 0; i < rateControl; i++) {
          this.listOfRatesSecondYear.push(this.formService.createNewRateForm(i));
        }
      }
    }
  }

  private disableFormControl(): void {
    if (this.form.get('financialAssistance.repetition')?.value) {
      this.form.get('financialAssistance.repetitionYear')?.enable();
    } else {
      this.form.get('financialAssistance.repetitionYear')?.enable();
      localStorage.removeItem('form-data.financialAssistance.repetitionYear');
    }
  }

  private clearRates(rateList: FormArray<any>) {
    while (rateList.length !== 0) {
      rateList.removeAt(0);
    }
  }

  protected readonly JSON = JSON;
}


