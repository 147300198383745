import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../core/services/data.service";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";
import {NgIf} from "@angular/common";
import {ApplicantDetailoverviewComponent} from "../applicant-detailoverview/applicant-detailoverview.component";
import {VkfDetailoverviewComponent} from "../vkf-detailoverview/vkf-detailoverview.component";

@Component({
  selector: 'app-detail-overview-pages-component',
  standalone: true,
  imports: [
    NgIf,
    ApplicantDetailoverviewComponent,
    VkfDetailoverviewComponent
  ],
  templateUrl: './detail-overview-pages-component.component.html',
  styleUrl: './detail-overview-pages-component.component.scss'
})
export class DetailOverviewPagesComponentComponent implements OnInit {
  activeUserRole: UserRoleEnum = UserRoleEnum.APPLICANT;

  constructor(private dataService: DataService) {
    // noop
  }

  ngOnInit() {
    this.dataService.getUserRole().subscribe((result)=>{
      this.activeUserRole = result.role.toUpperCase();
    })
  }

  protected readonly UserRoleEnum = UserRoleEnum;
}
