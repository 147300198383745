<ng-container *transloco="let t" id="sponsoring-value-two-years" [formGroup]="form">
  <ng-container formGroupName="sponsoringValue">
    <div class="taxOption sectionSpace">
      <p class="font-c2 sponsoringTax">{{ t('wizard.sponsoringValue.tax') }}</p>
      <mat-radio-group formControlName="taxFree" (change)="changeTaxOption($event)">
        <mat-radio-button [value]="true"><p>{{ t('common.yes') }}</p></mat-radio-button>
        <mat-radio-button [value]="false"><p>{{ t('common.no') }}</p></mat-radio-button>
      </mat-radio-group>
    </div>
    <app-hint *ngIf="this.form.get('sponsoringValue.taxFree')?.value" [hintDescription]="t('wizard.sponsoringValue.hints.dataUpload')"></app-hint>

    <ng-container id="firstYear">
      <h3 class="sectionHeader element-space element-space-top">{{ t("wizard.sponsoringValue.firstYear") }}</h3>
      <mat-form-field *ngIf="!this.form.get('sponsoringValue.taxFree')?.value" class="full-with">
        <label class="font-c2">{{ t("common.brutto") }} <span matPrefix>€&nbsp;</span></label>
        <input type="number"  matInput placeholder="{{t('wizard.sponsoringValue.placeholderBrutto')}}" [formControlName]="'sponsoringValueGrossAmountFirstYear'"/>
        <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringValueGrossAmountFirstYear', form)">
          {{this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.errors?.['min'] ? t('error.negative') : t('error.required') }}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-with">
        <label class="font-c2">{{ this.form.get('sponsoringValue.taxFree')?.value  ? t("common.nettoBrutto") : t("common.netto") }} <span matPrefix>€&nbsp;</span></label>
        <input type="number"  matInput placeholder="{{t('wizard.sponsoringValue.placeholderNetto')}}"
               [formControlName]="'sponsoringValueNetAmountFirstYear'"/>

        <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringValueNetAmountFirstYear', form)">
          {{this.form.get('sponsoringValue.sponsoringValueNetAmountFirstYear')?.errors?.['min'] ? t('error.negative') : t('error.required') }}</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container id="secondYear">
      <h3 class="sectionHeader element-space element-space-top">
        {{ t("wizard.sponsoringValue.secondYear") }}
      </h3>
      <mat-form-field *ngIf="!this.form.get('sponsoringValue.taxFree')?.value" class="full-with">
        <label class="font-c2">{{ t("common.brutto") }} <span matPrefix>€&nbsp;</span></label>
        <input type="number"   matInput placeholder="{{t('wizard.sponsoringValue.placeholderBrutto')}}" [formControlName]="'sponsoringValueGrossAmountSecondYear'"/>
        <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringValueGrossAmountSecondYear', form)">
          {{this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.errors?.['min'] ? t('error.negative') : t('error.required') }}</mat-error>
      </mat-form-field>
      <mat-form-field class="full-with">
        <label class="font-c2">{{ this.form.get('sponsoringValue.taxFree')?.value  ? t("common.nettoBrutto") : t("common.netto") }}<span matPrefix>€&nbsp;</span></label>
        <input type="number" matInput placeholder="{{t('wizard.sponsoringValue.placeholderNetto')}}"
               [formControlName]="'sponsoringValueNetAmountSecondYear'"/>
        <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringValueNetAmountSecondYear', form)">
          {{this.form.get('sponsoringValue.sponsoringValueNetAmountSecondYear')?.errors?.['min'] ? t('error.negative') : t('error.required') }}</mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isSponsoringValueTwoYearsInvalid(form)"></app-wizard-footer>
</ng-container>
