import { Component } from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {ValidationService} from "../../../shared/services/validation-service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-wizard-sponsored',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    WizardFooterComponent,
    FormsModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    HintComponent,
    StepperComponent,
    MatError,
    NgIf
  ],
  templateUrl: './sponsored-information.html',
  styleUrl: './sponsored-information.scss'
})
export class SponsoredInformation {
  form = this.formService.buildForm();

  constructor(public wizardService: WizardService,
              public formService: FormService,
              public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
  }
}
