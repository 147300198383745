<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header  [title]="t('wizard.sponsoringDuration.title')"></app-wizard-header>

  <div class="container text-center">
    <mat-card>
      <h3>{{t('wizard.sponsoringDuration.contentTitle')}}</h3>
      <p class="font-c1 element-space">{{t('wizard.sponsoringDuration.content')}}</p>
      <div class="buttonGroup">
        <app-text-button
          [isPrimaryButton]="true"
          [buttonText]="t('buttons.oneYear')"
          (click)="onClickButton(1)"
        />
        <app-text-button
          [isPrimaryButton]="true"
          [buttonText]="t('buttons.twoYears')"
          (click)="onClickButton(2)"
        />
      </div>
    </mat-card>
  </div>
</ng-container>
