import {Component, OnDestroy, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {MatCalendar, MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {MatCard} from "@angular/material/card";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {Subscription} from "rxjs";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {ValidationService} from "../../../shared/services/validation-service";
import {MatError} from "@angular/material/form-field";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-wizard-sponsoring-start',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoModule,
    WizardHeaderComponent,
    HintComponent,
    MatDatepicker,
    MatDatepickerModule,
    MatCard,
    MatCalendar,
    ReactiveFormsModule,
    WizardFooterComponent,
    StepperComponent,
    MatError,
    NgIf
  ],
  providers: [
    MatDatepickerModule,
  ],
  templateUrl: './sponsoring-start-date.html',
  styleUrl: './sponsoring-start-date.scss'
})
export class SponsoringStartDate implements OnInit, OnDestroy{
  form = this.formService.buildForm();
  currentDate = new Date();
  sponsoringDurationControl: FormControl = this.form.get('sponsoringValue.sponsoringDuration') as FormControl;
  private sponsoringDurationSubscription: Subscription = {} as Subscription;

  constructor(public wizardService: WizardService, public formService: FormService, public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
  }

  ngOnInit() {
    this.sponsoringDurationSubscription = this.sponsoringDurationControl.valueChanges.subscribe(() => {
      this.disableDateFields();
    });
    this.disableDateFields();
  }

  ngOnDestroy() {
    if (this.sponsoringDurationSubscription) {
      this.sponsoringDurationSubscription.unsubscribe();
    }
  }

  disableDateFields(): void {
    const sponsoringDuration = this.sponsoringDurationControl.value;
    if (sponsoringDuration) {
      this.currentDate = new Date();
      this.currentDate.setDate(this.currentDate.getDate() + (sponsoringDuration === 1 ? 15 : 10 * 7));
    }
  }

  updateFormDate(date: Date | null) {
    if (date) {
      const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0); // Set time to noon
      this.form.get('sponsoringValue.sponsoringStartDate')?.setValue(adjustedDate);
    } else {
      this.form.get('sponsoringValue.sponsoringStartDate')?.setValue(null);
    }
  }

  setDatePickerDescriptionText(): string {
    return this.sponsoringDurationControl.value === 1 ?
      translate('wizard.sponsoringStart.descriptionSponsoringOneYear') :
      translate('wizard.sponsoringStart.descriptionSponsoringMore')
  }
}
