<ng-container [formGroup]="form" *transloco="let t">
  <div class="item">
    <div class="key-value-pair row">
      <div class="key col-lg-6">{{ t('formFieldMapping.financialAssistance.payment.labelSecondYear')}}</div>
      <div class="value col-lg-6">
        {{t('formFieldMapping.financialAssistance.payment.' + (isFullPayedSecondYear === true ? 'fullPayed' : 'payedInRates')) }}</div>
    </div>
  </div>

  <div class="item" *ngIf="!isFullPayedSecondYear">
    <div class="key-value-pair row">
      <div class="key col-lg-6">
        {{ t('formFieldMapping.financialAssistance.payment.rateAmount') }}
      </div>
      <div class="value col-lg-6">
        {{ getValue('financialAssistance.secondYear.rateAmount') }}
      </div>
    </div>
  </div>

  <ng-container *ngFor="let rate of getValue('financialAssistance.secondYear.rates') || []; let i = index">
    <div class="item">
      <h5>{{ t('formFieldMapping.financialAssistance.rates.' + (i + 1)) }}</h5>

      <div class="key-value-pair row">
        <div class="key col-lg-6">{{ t('formFieldMapping.financialAssistance.grossAmount') }}</div>
        <div class="value col-lg-6">{{ rate.value }}</div>
      </div>
      <div class="key-value-pair row">
        <div class="key col-lg-6">{{ t('formFieldMapping.financialAssistance.date') }}</div>
        <div class="value col-lg-6">{{ rate.date | date: 'dd.MM.YYYY' }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>
