import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { IconsComponent } from '../icons/icons.component';
import { FormService } from '../../services/form-service/form.service';
import { BytesToSizePipe } from '../../pipes/bytesToSize.pipe';
import { HttpClient } from '@angular/common/http';
import { FileUploadService } from '../file-uploader/file-uploader-service';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { IconTextButtonComponent } from '../button/icon-text-button/icon-text-button.component';
import {DataService} from "../../../core/services/data.service";

@Component({
  selector: 'app-file-viewer',
  standalone: true,
  imports: [NgIf, IconsComponent, NgForOf, DatePipe, BytesToSizePipe, TextButtonComponent, IconTextButtonComponent],
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnChanges {
  @Input() selectedFiles: File[] = [];
  @Input() purpose: Array<string> = [''];
  @Input() downloadedFiles: any;
  @Output() fileDeleted = new EventEmitter<File>();

  form = this.formService.buildForm();
  allFiles = this.fileUploadService.getData();
  downloadedFilesList: Array<{id: '', name:'', size:4, type:'', purpose:'', date:''}> = [];

  constructor(private formService: FormService,
              private http: HttpClient,
              public dataService: DataService,
              public fileUploadService: FileUploadService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.purpose.length > 1) {
      const generatedApplication = this.downloadedFiles?.documents?.[this.purpose[0]];
      const generatedContract = this.downloadedFiles?.documents?.[this.purpose[1]];
      this.downloadedFilesList =  [...generatedApplication, ...generatedContract];
    } else {
      this.downloadedFilesList = this.downloadedFiles?.documents?.[this.purpose[0]];
    }



  }


  extractBase64(content: string): string {
    // Unterstützt PDF, PPT, JPEG, DOCX, MP3, MP4
    const match = content.match(/^data:(application\/pdf|application\/vnd\.ms-powerpoint|application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation|image\/jpeg|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document|audio\/mpeg|video\/mp4);base64,(.+)$/);
    return match ? match[2] : content;
  }

  cleanBase64(base64: string): string {
    let cleaned = base64.replace(/[^A-Za-z0-9+/=]/g, '');
    while (cleaned.length % 4 !== 0) {
      cleaned += '=';
    }
    return cleaned;
  }

  downloadFile(file: any): void {
    if (!file || !file.content) {
      console.error('Invalid file or empty content');
      return;
    }
    let blob;
    if (typeof file.content === 'string') {
      let base64Content = this.extractBase64(file.content);
      const cleanedContent = this.cleanBase64(base64Content);

      try {
        const byteCharacters = atob(cleanedContent);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        blob = new Blob([byteNumbers], { type: file.type || this.getMimeType(file.name) });
      } catch (error) {
        console.error('Failed to decode Base64 content:', error);
        return;
      }
    } else {
      blob = new Blob([file.content], { type: file.type || 'application/octet-stream' });
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = file.name || 'download';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }


  downloadUploadedFile(file: any): void {
    this.dataService.downloadSelectedDocumentByDocumentId(file.id).subscribe(
      () => {},
      (error) => {
        console.error(`Failed to download the file ${file.name}`, error);
      }
    );
  }

  deleteUploadedFile(file: any): void {
    this.dataService.deleteDocuments(file.id).subscribe(
      () => {
        console.log(`File ${file.name} deleted successfully.`);
        if(this.downloadedFilesList){
          console.log(this.downloadedFilesList);
          this.downloadedFilesList = this.downloadedFilesList.filter(item => item.id !== file.id);
        }
      },
      (error) => {
        console.error(`Failed to delete the file ${file.name}`, error);
      }
    );
  }


  getMimeType(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf': return 'application/pdf';
      case 'ppt': return 'application/vnd.ms-powerpoint';
      case 'pptx': return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpeg':
      case 'jpg': return 'image/jpeg';
      case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'mp3': return 'audio/mpeg';
      case 'mp4': return 'video/mp4';
      default: return 'application/octet-stream';
    }
  }

  protected readonly JSON = JSON;
}
