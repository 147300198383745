import {Component, OnInit} from '@angular/core';
import {IconTextButtonComponent} from "../../shared/components/button/icon-text-button/icon-text-button.component";
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslocoModule} from "@ngneat/transloco";
import {DataService} from "../../core/services/data.service";
import {IconsComponent} from "../../shared/components/icons/icons.component";
import {HintComponent} from "../../shared/components/hint/hint.component";
import {MatCard} from "@angular/material/card";
import {Alert} from "../../shared/components/alert/alert";
import {AddNewApplicationComponent} from "./new-application/add-new-application.component";
import {ApplicationOverviewTableComponent} from "./application-overview/application-overview-table.component";
import {DashboardHowToMediaComponent} from "./how-to-media/dashboard-how-to-media.component";
import {LoginComponent} from "../login/login.component";
import {ApplicationDto} from "../../api/applicationDto";
import {StepperComponent} from "../../shared/components/stepper/stepper.component";
import {UserRoleEnum} from "../../api/enum/userRoleEnum";
import {AuthenticationService} from "../../core/services/authentication.service";
import {take} from "rxjs";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    IconTextButtonComponent,
    NgIf,
    TranslocoModule,
    AsyncPipe,
    NgTemplateOutlet,
    IconsComponent,
    Alert,
    AddNewApplicationComponent,
    ApplicationOverviewTableComponent,
    HintComponent,
    DashboardHowToMediaComponent,
    MatCard,
    Alert,
    LoginComponent,
    StepperComponent,
    NgForOf
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  allApplications: Array<ApplicationDto> = [];
  allNotifications: Array<any> = [];

  userRole = '';

  constructor(public dataService: DataService, public authenticationService: AuthenticationService) {
  // noop
  }

  ngOnInit() {
    this.dataService.getUserRole().subscribe((result)=>{
      this.userRole = result.role;
    });
    this.dataService.getAllApplicationsForDashboard().subscribe((result) => {
      this.allApplications = result;
    });

    this.dataService.getAllNotifications().subscribe((result) => {
      this.allNotifications  = result;
    });
  }

  protected readonly UserRoleEnum = UserRoleEnum;
}
