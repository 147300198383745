import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatFormField, MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatInput, MatInputModule} from "@angular/material/input";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatNativeDateModule, MatOption} from "@angular/material/core";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";
import {TranslocoDirective} from "@ngneat/transloco";
import {FormService} from "../../services/form-service/form.service";
import {FileTypePipe} from "../../pipes/fileTypePipe";
import {TextButtonComponent} from "../button/text-button/text-button.component";
import {MatIcon} from "@angular/material/icon";
import {IconTextButtonComponent} from "../button/icon-text-button/icon-text-button.component";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-edit-form-dialog',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    NgForOf,
    MatButton,
    MatInput,
    NgIf,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioButton,
    MatRadioGroup,
    TranslocoDirective,
    FileTypePipe,
    NgSwitch,
    NgSwitchCase,
    TextButtonComponent,
    MatIcon,
    IconTextButtonComponent,
    MatOption,
    MatSelect,
    JsonPipe,
    NgClass,
  ],
  templateUrl: './edit-form-dialog.component.html',
  styleUrl: './edit-form-dialog.component.scss'
})
export class EditFormDialogComponent implements OnInit{
  field = '';
  keyList: Array<{data: FormControl, field: string}> = [];

  get services(): FormArray {
    return this.form.get('entitlements.services') as FormArray;
  }

  get activeGroup(): FormGroup {
    return this.form.get(this.groupName) as FormGroup;
  }

  form = this.formService.buildForm();
  groupName = '';

  constructor(public formService: FormService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<EditFormDialogComponent>,
              public fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.groupName = data.name;
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);

    Object.keys(this.activeGroup.controls).forEach((field) => {
      this.getFirstFormControl(this.activeGroup.get(field), field)
    })
  }

  getFirstFormControl(data: any, field?: string): any | null {
    if (data instanceof FormControl) {
      if (field != null) {
        this.keyList.push({data, field});
      }
      return data;
    }
    else if (data instanceof FormGroup) {
      for (const key of Object.keys(data.controls)) {
        this.getFirstFormControl(data.controls[key], field + '.' + key);
      }
    }
    else if (data instanceof FormArray) {
      for (let i = 0; i < data.controls.length; i++) {
        this.getFirstFormControl(data.controls[i], field + '[' + i + ']');
      }
    }
  }

  isFieldReadonly(field: string): boolean {
    const notEditableControls = ['personal_number', 'email'];
    return notEditableControls.includes(field);
  }

  isFormValid(): boolean {
    let isValid = true;
    this.keyList.forEach((listelement) => {
      if (!listelement.data.valid) {
        isValid = false;
      }
    });
    return isValid;
  }

  changeValue(event: MatRadioChange) {
    if (event) {
      // this.addRateObjectToList(event.value);
      this.formService.setFormData(this.form);
    }
  }

  closeDialog(form?: FormGroup) {
    form ? this.dialogRef.close(form) : this.dialogRef.close();
  }

  saveFormValues() {
    this.formService.setFormData(this.form);
    this.closeDialog(this.form);
  }


  getFormControlErrors(control: FormControl): string | null {
    if (control && control.errors) {
      const errors = control.errors;
      for (const errorKey in errors) {
        if (errors.hasOwnProperty(errorKey)) {
          return errorKey;
        }
      }
    }
    return null;
  }
}
