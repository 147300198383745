<ng-container *transloco="let t">
  <app-wizard-header [viewMode]="true" [status]="this.applicationData?.sponsoringValue?.application_state"
                     [title]=" t('wizardTitles.vkfOverviewTitle', {organisation: this.applicationData?.sponsored.sponsoredName})"></app-wizard-header>

  <div class="container">
    <div *ngIf="!editMode">
      <mat-card>
        <h3>{{ this.stateTextService.returnTextForApplicant(this.applicationData?.sponsoringValue?.application_state).title }}</h3>
        <p
          class="element-space">{{ this.stateTextService.returnTextForApplicant(this.applicationData?.sponsoringValue?.application_state).content }}</p>
        <div class="buttonGroup">
          <app-text-button (click)="switchToEditMode(false)">test</app-text-button>
          <ng-container
            *ngFor="let button of this.stateTextService.returnTextForApplicant(this.applicationData?.sponsoringValue?.application_state).button">
            <app-text-button
              [isButtonReadOnly]="button.disabled"
              (click)="switchToEditMode(button.disabled)"
              [buttonText]="button.text"
              [isPrimaryButton]="true">
            </app-text-button>
          </ng-container>
        </div>
      </mat-card>
    </div>

    <app-hint *ngIf=" !editMode &&
    this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_NEEDED ||
     this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.OPEN
"
      [hintDescription]="'Bitte beachten Sie, dass Sie zusätzliche Dateien am Ende der Seite hinzufügen können, falls weitere Anpassungen notwendig sind.'"></app-hint>

    <form [formGroup]="form">
      <div *ngFor="let section of sections">
        <ng-container>
          <ng-container *ngIf="section.id === 'financialAssistance'; else defaultKeyValues">
            <app-financial-assistance-key-value-component
              [isEditable]="isFormEditable()"
              [data]="applicationData"></app-financial-assistance-key-value-component>
          </ng-container>

          <ng-template #defaultKeyValues>
            <app-key-value-card-component
              [isEditable]="isFormEditable()"
              [marcCorrectionArea]="!!(editMode && getCommentsForSection(section.id))"
              [title]="t(section.title)"
              [data]="applicationData?.[section.dataKey]">
            </app-key-value-card-component>
          </ng-template>

          <ng-container *ngIf="section.id === 'dataUpload'">
            <app-file-uploader></app-file-uploader>
          </ng-container>
          <div *ngIf="section.id === 'dataUpload'" class="row element-space">
            <div class="col-lg-12 addDataButton">
              <!-- todo svenja sollen wir ein endpunkt nur für datein erstellen -->
              <app-text-button
                [isPrimaryButton]="true"
                [isButtonReadOnly]="false" buttonText="Weitere Datei(en) hochladen"></app-text-button>
            </div>
          </div>

          <div class="col-lg-12">
            <h4 *ngIf="editMode">Kommentare</h4>
            <ng-container *ngFor="let comment of getCommentsForSection(section.id)">
              <div class="col-lg-12 element-space">
                <h4 class=" col-lg-12"><b>Von ERGO Sponsoring-Manager</b>  {{ comment.authorName }}
                  am {{ comment.created_at | date:'dd.MM.yyyy' }}</h4>
                <p class="font-c2">{{ comment.content }}</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </form>

    <div class="col-lg-12 textBox element-space">
      <app-media-box [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
    </div>


    <div *ngIf="editMode">
      <div class="col-lg-12 buttonGroupBetween">
        <app-text-button [isPrimaryButton]="false" [buttonText]="t('buttonText.dashboard')"
                         (click)="navigateToDashboard()"></app-text-button>
        <app-text-button [isPrimaryButton]="true" [buttonText]="t('buttonText.correctionAdded')"
                         (click)="changeStateToCorrectionsNeeded('accepted')"></app-text-button>

      </div>
    </div>
  </div>

  <ng-template #mediaBoxContent>
      <app-icons [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
      <h5>{{ t('dashboard.mediaPart.helpTitle') }}</h5>
      <p class="font-c3">{{ t('dashboard.mediaPart.helpDescription') }}</p>
  </ng-template>
</ng-container>


