<mat-card *transloco="let t" [formGroup]="form" class="col-lg-12">
  <mat-card-title class="title">
    <div> {{ t('wizard.financial.title') }}</div>
    <div class="button-group">
      <button *ngIf="isEditable" (click)="openEditDialog()">
        <p class="font-c2">Bearbeiten</p>
        <mat-icon class="icon" svgIcon="edit-icon"></mat-icon>
      </button>
      <button (click)="closeCard()">
        <mat-icon class="icon" [svgIcon]="isCardOpen ? 'open-accordion' : 'close-accordion'"></mat-icon>
      </button>
    </div>
  </mat-card-title>

  <mat-card-content *ngIf="isCardOpen">
    <ng-container id="firstYear">
      <app-first-year [isEdited]="isEdited" [data]="data"></app-first-year>
      <app-second-year [isEdited]="isEdited" [data]="data"></app-second-year>

      <div class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">
            {{ t('formFieldMapping.financialAssistance.zuschuss.label') }}
          </div>
          <div class="value col-lg-6">
            {{ t('common.' + getValue('financialAssistance.zuschuss')) }}
          </div>
        </div>
      </div>

      <div class="item">
        <div class="key-value-pair row">
          <div class="key col-lg-6">
            {{ t('formFieldMapping.financialAssistance.repetition.label') }}
          </div>
          <div class="value col-lg-6">
            {{ t('common.' + getValue('financialAssistance.repetition')) }} {{ getValue('financialAssistance.repetitionYear') }}
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
