<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.entitlements.title')"></app-wizard-header>

  <ng-container formGroupName="entitlements">
    <div class="container">

      <ng-container id="services">
        <h3 class="subtitle">{{ t('wizard.entitlements.subtitle1') }}</h3>
        <app-hint [hintDescription]="t('wizard.entitlements.hint')"></app-hint>
        <div class="services">
          <div formArrayName="services">
            <div *ngFor="let control of services.controls; let i = index">
              <mat-form-field class="full-with" appearance="fill">
                <label class="font-c2">{{ i <2 ?  ('Leistungsbestandteil' + ' ' + (i + 1)) :  ('Leistungsbestandteil' + ' ' + (i + 1) + ' ' +'(optional)') }}</label>
                <textarea placeholder="min. 10 Zeichen" matInput [formControlName]="i"></textarea>
                <mat-error class="font-c3"
                           *ngIf="this.form.get('entitlements.services.' + i)?.touched &&
                          this.form.get('entitlements.services.' + i)?.invalid">
                  {{form.get('entitlements.services.' + i)?.hasError('required') ? t('error.required') : t('error.min')}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <app-icon-text-button class="element-space" (click)="addNewLeistung()" [textColor]="'white'" [iconName]="'add-icon'" [buttonText]="t('buttonText.addMore')"></app-icon-text-button>
        <app-hint [hintDescription]="t('wizard.entitlements.hint2')"></app-hint>
      </ng-container>


      <ng-container id="hospitality" formGroupName="hospitality">
        <h3 class="subtitle">{{ t('wizard.entitlements.subtitle1') }}</h3>

        <ng-container id="hospitality-radio">
          <p class="font-c2">{{ t('wizard.entitlements.hospitalityRadio') }}</p>
          <mat-radio-group formControlName="isHospitality" (change)="isHospitalityServiceAvailable($event)">
            <mat-radio-button [value]="true"><p>{{ t('common.yes') }}</p></mat-radio-button>
            <mat-radio-button [value]="false"><p>{{ t('common.no') }}</p></mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <mat-form-field *ngIf="this.form.get('entitlements.hospitality.isHospitality')?.value" class="full-with" appearance="fill">
          <label class="font-c2">Welche?</label>
          <textarea placeholder="min. 10 Zeichen" matInput [formControlName]="'hospitalityService'"></textarea>
          <mat-error class="font-c3"
                     *ngIf="this.form.get('entitlements.hospitality.hospitalityService')?.touched &&
                          this.form.get('entitlements.hospitality.hospitalityService')?.invalid">
            {{form.get('entitlements.hospitality.hospitalityService')?.hasError('required') ? t('error.required') : t('error.min')}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-with">
          <label class="font-c2">{{ t("wizard.entitlements.labelBruttoHospitality") }}</label>
          <input placeholder="xxx,xx €" type="number" matInput [formControlName]="'hospitalityValue'"/>
          <mat-error class="font-c3"
                     *ngIf="this.form.get('entitlements.hospitality.hospitalityValue')?.touched &&
                          this.form.get('entitlements.hospitality.hospitalityValue')?.invalid">{{t('error.required')}}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container id="titleRight">
        <h3 class="subtitle">{{ t('wizard.entitlements.subtitle3') }}</h3>
        <mat-form-field class="full-with" appearance="fill">
          <label class="font-c2">{{ t('wizard.entitlements.titleRight.text') }}</label>
          <textarea matInput [formControlName]="'titleRight'"></textarea>
          <mat-error class="font-c3"
                     *ngIf="this.form.get('entitlements.hospitality.hospitalityValue')?.touched &&
                          this.form.get('entitlements.hospitality.hospitalityValue')?.invalid">{{t('error.required')}}
          </mat-error>
        </mat-form-field>
        <app-hint [hintDescription]="t('wizard.entitlements.titleRight.titleRightHint')"></app-hint>
      </ng-container>

      <ng-container id="exclusivity">
        <h3 class="subtitle">{{ t('wizard.entitlements.subtitle4') }}</h3>

        <p class="font-c2">{{ t('wizard.entitlements.exclusivity.radioText') }}</p>
        <mat-radio-group formControlName="exclusivity" (change)="isExclusivitySelected($event)">
          <mat-radio-button [value]="true"><p>{{ t('common.yes') }}</p></mat-radio-button>
          <mat-radio-button [value]="false"><p>{{ t('common.no') }}</p></mat-radio-button>
        </mat-radio-group>

        <app-hint [hintDescription]="t('wizard.entitlements.exclusivity.hint')"></app-hint>
      </ng-container>
    </div>
  </ng-container>



  <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isEntitlementsInvalid(form)"></app-wizard-footer>
</ng-container>
