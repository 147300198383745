import {Component, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {WizardFooterComponent} from "../wizard-footer/wizard-footer.component";
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../../../shared/services/form-service/form.service";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {FormErrorDirective} from "../../../shared/directives/form-error-directive";
import {NgIf} from "@angular/common";
import {ValidationService} from "../../../shared/services/validation-service";
import {DataService} from "../../../core/services/data.service";

@Component({
  selector: 'app-wizard-sponsoring-applicantinformation',
  standalone: true,
  imports: [
    TextButtonComponent,
    TranslocoModule,
    WizardHeaderComponent,
    WizardFooterComponent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    StepperComponent,
    FormErrorDirective,
    MatError,
    NgIf
  ],
  templateUrl: './applicant-information.html',
  styleUrl: './applicant-information.scss'
})
export class ApplicantInformation implements OnInit{
  form = this.formService.buildForm();
  submitted = false;

  constructor(public wizardService: WizardService,
              public dataService: DataService,
              public formService: FormService,
              public validationService: ValidationService) {
    this.formService.initializeForm(this.form);
  }

  ngOnInit(): void {
    this.loadDataFromDatabase() ?
    this.dataService.getUserById().subscribe((result) => {
      this.form.get('applicant')?.setValue(result);
      this.form.patchValue(result);
    }) : '';
  }

  loadDataFromDatabase(): boolean {
    const controls = this.form.get('applicant')?.value;
    if (!controls) {
      return false;
    }
    return Object.values(controls).every(value => value === null);
  }

}
