import {Component, OnInit} from '@angular/core';
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";
import {
  FinancialAssistanceKeyValueComponent
} from "../../../shared/components/financial-assistance-table-component/financial-assistance-key-value-component";
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AbstractControl,
  Form, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {MediaBoxComponent} from "../../../shared/components/media-box/media-box.component";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {applicationStateEnum} from "../../../api/enum/stateEnum";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {
  FinancialKeyComponent
} from "../../../shared/components/financial-assistance-table-component/financial-key/financial-key.component";
import {ApplicantDetailOverviewService} from "./applicant-detail-overview.service";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {classNames} from "@angular/cdk/schematics";


@Component({
  selector: 'app-applicant-detailoverview',
  standalone: true,
  imports: [
    FileUploaderComponent,
    FinancialAssistanceKeyValueComponent,
    KeyValueCardComponentComponent,
    StepperComponent,
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    ReactiveFormsModule,
    HintComponent,
    IconTextButtonComponent,
    MatCard,
    NgForOf,
    NgIf,
    DatePipe,
    FormsModule,
    MediaBoxComponent,
    IconsComponent,
    MatProgressSpinner,
    FinancialKeyComponent,
    FileViewerComponent,
  ],
  templateUrl: './applicant-detailoverview.component.html',
  styleUrl: './applicant-detailoverview.component.scss'
})
export class ApplicantDetailoverviewComponent implements OnInit{
  sections = [
    { id: 'sponsoringValue', title: 'keyValueTitles.sponsoringValue', dataKey: 'sponsoringValue' },
    { id: 'applicant', title: 'keyValueTitles.applicant', dataKey: 'applicant' },
    { id: 'sponsored', title: 'keyValueTitles.sponsored', dataKey: 'sponsored' },
    { id: 'financialAssistance', title: 'keyValueTitles.financialAssistance', dataKey: 'financialAssistance' },
    { id: 'entitlements', title: 'keyValueTitles.entitlements', dataKey: 'entitlements' },
    { id: 'dataUpload', title: 'keyValueTitles.dataUpload', dataKey: 'dataUpload' }
  ];
  isLoading = true;
  editMode = false;

  form = this.formService.buildForm();
  applicationId = '';
  selectedFiles: Array<any>=[];

  commentForm = {} as FormGroup;
  applicationStateEnum = applicationStateEnum;
  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};
  applicationData: any = null;

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public applicantDetailOverviewService: ApplicantDetailOverviewService,
              public formService: FormService,
              public fileUploadService: FileUploadService,
              public dataService: DataService,
              public router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
  ) {
    this.commentForm = this.fb.group({
      comments: this.fb.group({
        applicant: this.fb.control(null),
        sponsoringValue: this.fb.control(null),
        financialAssistance: this.fb.control(null),
        entitlements: this.fb.control(null),
        sponsored: this.fb.control(null),
        dataUpload: this.fb.control(null)
      })
    });
  }

  ngOnInit() {
    this.formService.initializeForm(this.form);


    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if(state.applicationId){
        this.applicationId = state.applicationId.toString();
        this.getDataForSelectedApplication(state.applicationId);
        this.getCommentsForSelectedApplication(state.applicationId);
        this.dataService.getContractDocuments(this.applicationId).subscribe((result) => {
          this.selectedFiles = result;
          console.log(result, '*r');
        });
      }
    });
  }

  isFormEditable(): boolean {
    return this.applicationData?.sponsoringValue?.application_state === applicationStateEnum.CORRECTION_NEEDED && this.editMode;
  }

  switchToEditMode(disabledButton: boolean) {
    if(!disabledButton){
      this.editMode = !this.editMode;
    }
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
    });
  }

  getCommentsForSelectedApplication(applicationId: string): void {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    if(this.applicationData?.application_state === applicationStateEnum.OPEN){
      return null;
    }
    let result: any;

    if(this.comments['comments']){
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if(key === section){
          result = value;
        }
      });
    }
    return result;
  }

  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  changeState(action: string): void {
    this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
      next: () => {
        this.updateApplication(action);
        this.navigateToDashboard();
      },
      error: (err) => {
        console.error('Status konnte nicht geändert werden', err);
      }
    });
  }

  get dataUploads(): FormArray {
    return this.form.get('dataUpload') as FormArray;
  }

  uploadAllFiles(files: File[]): void {
    files.forEach(file => {
      this.dataUploads.push(new FormControl(this.fileToFileMetadata(file)));
    });
    this.saveFormData();
  }

  private fileToFileMetadata(file: File): any {
    return {
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type
    };
  }

  private saveFormData(): void {
    const formData = this.form.getRawValue();
    localStorage.setItem('form-data', JSON.stringify(formData));
  }

  updateApplication(state: string) {
    const formData = this.formService.getFormData();
    const dataArray = this.form.get('dataUpload') as FormArray;
    const dataToUpload = this.fileUploadService.getData();
    dataToUpload.forEach(dataItem => {
      dataArray.push(this.fb.control(dataItem));
    });
    formData.dataUpload = dataToUpload;
    this.dataService.putApplication(this.applicationId, formData).subscribe({

      next: () => {
        this.changeState(state);
        this.formService.removeFormData();
      },
      error: (err) => {
        console.error('An error occurred:', err);
      }
    });
  }

  saveDocumentsForApplication(): void {
    const files = this.fileUploadService.getData();
    console.log(files, '**files');

    this.dataService.uploadDocuments(this.applicationId, files ).subscribe((result) => {
      console.log(result, '**result');
    });
  }

  trackByIndex = (index: number): number => {
    return index;
  };
}
