<div id="detailViews">
  <ng-container *ngIf="activeUserRole === UserRoleEnum.APPLICANT">
    <app-applicant-detailoverview></app-applicant-detailoverview>
  </ng-container>

  <ng-container *ngIf="activeUserRole === UserRoleEnum.VKF">
    <app-vkf-detailoverview></app-vkf-detailoverview>
  </ng-container>

  <ng-container *ngIf="activeUserRole === UserRoleEnum.RD_SUPERVISOR">
    <app-rd-detailoverview></app-rd-detailoverview>
  </ng-container>

  <ng-container *ngIf="activeUserRole === UserRoleEnum.GLVKF">
    <app-glvkf-detailoverview></app-glvkf-detailoverview>
  </ng-container>
</div>

