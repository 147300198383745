import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForOf, NgIf } from '@angular/common';
import { IconsComponent } from '../icons/icons.component';
import { MatCard } from '@angular/material/card';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { FormService } from '../../services/form-service/form.service';
import {FileUploadService} from "./file-uploader-service";
import {DataService} from "../../../core/services/data.service";

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    NgIf,
    IconsComponent,
    MatCard,
    TextButtonComponent,
    TranslocoDirective,
    NgForOf,
    FileViewerComponent,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  form = this.formService.buildForm();
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() applicationId = '';
  @Input() purpose = '';

  selectedFiles: File[] = [];
  isDragOver = false;

  constructor(private http: HttpClient, public formService: FormService, public fileUploadService: FileUploadService, public dataService: DataService) {
    this.initializeForm();
  }

  ngOnInit() {

  }

  onFileSelected(event: any) {
    const files = event.target.files;
    this.processFiles(files);
  }

  onFileDeleted(deletedFile: File) {
    this.selectedFiles = this.selectedFiles.filter(file => file !== deletedFile);
    this.emitSelectedFiles(this.selectedFiles);
  }


  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.processFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  private isValidFile(file: File): boolean {
    const allowedTypes = [
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'image/jpeg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'audio/mpeg',
      'video/mp4'
    ];
    const maxSizeInMB = 50;
    const isValidType = allowedTypes.includes(file.type);
    const isValidSize = file.size <= maxSizeInMB * 1024 * 1024; // Convert MB to bytes
    return isValidType && isValidSize;
  }

  private processFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (this.isValidFile(file)) {
        this.readFile(file);
      } else {
        console.error('Invalid file type or size:', file);
      }
    }
    this.emitSelectedFiles(files);
  }


  private readFile(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result as string;
      const fileData = {
        name: file.name,
        type: file.type,
        size: file.size,
        date: file.lastModified,
        purpose: this.purpose,
        content: fileContent,
      };
      this.fileUploadService.setData(fileData);
    };
    reader.readAsDataURL(file);
  }

  private emitSelectedFiles(files: any) {
    this.filesSelected.emit(this.selectedFiles);
  }

  private initializeForm(): void {
    const formData = this.formService.getFormData();

    if (formData.dataUpload) {
      this.selectedFiles = formData.dataUpload;
    }
    this.form.patchValue(formData);
  }
}
