import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {IconService} from "../../services/IconService";

@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [
    NgIf,
    MatIcon
  ],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss'
})

export class IconsComponent {
@Input() circleIcon = false;
@Input() iconSize = '';
@Input() iconName = '';
@Input() iconStyle = '';
@Input() iconColor = '';
@Input() bgColor = '';

constructor(iconService: IconService) {
}
}
