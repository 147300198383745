<ng-container *transloco="let t" id="dashboard-component">
  <div class="dashboard">
    <div class="container">

      <div class="dashboard-title">
        <h1>{{ t('dashboard.emptyDashboard.title') }}</h1>
      </div>

      <mat-card *ngIf="userRole === 'applicant'" class="gray-box">
        <h2 class="card-content-header">{{ t('dashboard.emptyDashboard.description') }}</h2>
        <app-dashboard-how-to-media></app-dashboard-how-to-media>
      </mat-card>

      <ng-container *ngIf="allApplications.length else emptyDashboard">
        <ng-container *ngIf="allNotifications.length > 0">
          <div class="notification-container">
            <h2>{{ t('dashboard.alerts.title') }}</h2>
            <div *ngFor="let notification of allNotifications">
              <app-alert [notification]="notification"></app-alert>
            </div>
          </div>
        </ng-container>

        <h2>{{ t('dashboard.title') }}</h2>
        <app-add-new-application *ngIf="userRole === 'applicant'" [emptyDashboard]="false"></app-add-new-application>
        <app-application-overview-table class="sectionSpaceDefault"
                                        [allApplications]="allApplications">
        </app-application-overview-table>

      </ng-container>
    </div>
  </div>

  <ng-template #emptyDashboard>
    <app-add-new-application></app-add-new-application>
  </ng-template>
</ng-container>
