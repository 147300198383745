import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatRadioButton, MatRadioGroup, MatRadioModule} from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {FormService} from "../../services/form-service/form.service";

@Component({
  selector: 'app-dynamic-form-field',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    NgIf,
  ],
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent {
  @Input() controlName!: string;
  @Input() groupName!: string;
  @Input() fieldType =  '';
  @Input() label = '';

  form = this.formService.buildForm();

  constructor(private formService: FormService) {
    this.formService.initializeForm(this.form);
    console.log(this.fieldType, '*fm');
  }



}
