import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormArray, FormControl, Validators} from '@angular/forms';
import { WizardService } from '../../../shared/services/wizard-service/wizard.service';
import {FormService} from "../../../shared/services/form-service/form.service";
import { TextButtonComponent } from '../../../shared/components/button/text-button/text-button.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { WizardHeaderComponent } from '../wizard-header/wizard-header.component';
import { FileUploaderComponent } from '../../../shared/components/file-uploader/file-uploader.component';
import { WizardFooterComponent } from '../wizard-footer/wizard-footer.component';
import { HintComponent } from '../../../shared/components/hint/hint.component';
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";

@Component({
  selector: 'app-wizard-sponsoring-data-upload',
  standalone: true,
    imports: [
        TextButtonComponent,
        TranslocoDirective,
        WizardHeaderComponent,
        FileUploaderComponent,
        WizardFooterComponent,
        HintComponent,
        FileViewerComponent,
        StepperComponent
    ],
  templateUrl: './sponsoring-data-upload.html',
  styleUrls: ['./sponsoring-data-upload.scss']
})
export class SponsoringDataUpload {
  form = this.formService.buildForm();

  get dataUploads(): FormArray {
    return this.form.get('dataUpload') as FormArray;
  }

  constructor(
    public wizardService: WizardService,
    public formService: FormService,
    public fb: FormBuilder
  ) {
    this.formService.initializeForm(this.form);
  }

  uploadAllFiles(files: File[]): void {
    files.forEach(file => {
      this.dataUploads.push(new FormControl(this.fileToFileMetadata(file)));
    });
    this.saveFormData();
  }
  private fileToFileMetadata(file: File): any {
    return {
      file_name: file.name,
      lastModified: file.lastModified,
      file_size: file.size,
      file_type: file.type,
      purpose: 'sonstiges_antrag'
    };
  }

  private saveFormData(): void {
    const formData = this.form.getRawValue();
    localStorage.setItem('form-data', JSON.stringify(formData));
  }
}
