<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.financial.title')"></app-wizard-header>

  <div class="container" formGroupName="financialAssistance">
    <ng-container class="col-lg-12">
      <ng-container *ngIf="(this.form.get('sponsoringValue.sponsoringDuration')?.value === 1) else twoYears">
        <!-- First Year -->
        <div class="col-lg-12">
          <mat-card class="col-lg-6">
            <mat-card-title class="title">
              <div class="font-cs">Sponsoringsumme</div>
            </mat-card-title>
            <mat-card-content>
              <p class="font-c2s">{{getSponsoringValue('firstYear')}}€</p>
            </mat-card-content>
          </mat-card>
        </div>

        <ng-container formGroupName="firstYear">
          <ng-container id="firstYearPayment">
            <p class="font-c2">Wie wird die Sponsoringsumme im 1. Jahr gezahlt?</p>
            <mat-radio-group formControlName="fullPayed" (change)="changePaymentMethod($event, 'firstYear', 1)">
              <mat-radio-button [value]="true"><p>{{ t('wizard.financial.payment.full') }}</p></mat-radio-button>
              <mat-radio-button [value]="false"><p>{{ t('wizard.financial.payment.rate') }}</p></mat-radio-button>
            </mat-radio-group>
          </ng-container>

          <ng-container id="firstYearRateAmount">
            <div class="col-lg-12">
              <mat-form-field appearance="fill" class="col-lg-1" *ngIf="!this.form.get('financialAssistance.firstYear.fullPayed')?.value">
                <mat-select [value]="this.form.get('financialAssistance.firstYear.rateAmount')?.value" (selectionChange)="onSelectRate($event.value, 'firstYear')">
                  <mat-option *ngFor="let rate of rates" [value]="rate">
                    {{ rate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container id="firstYearRates">
            <div formArrayName="rates">
              <div *ngFor="let control of listOfRatesFirstYear.controls; let i = index" [formGroupName]="i">
                <div class="col-lg-12 two-element-row">
                  <mat-form-field *ngIf="this.form.get('financialAssistance.firstYear.fullPayed')?.value === false" class="col-lg-6">
                    <label class="font-c2">{{ t('formFieldMapping.rate.label.' + (i +1)) }}</label>
                    <input type="number" placeholder="xxx,xx €" matInput formControlName="value">
                    <mat-error class="font-c3"
                               *ngIf="this.form.get('financialAssistance.firstYear.rates.' + i + '.value')?.touched &&
                  this.form.get('financialAssistance.firstYear.rates.' + i + '.value')?.invalid">{{t('error.required')}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-lg-6">
                    <label class="font-c2">{{ t('formFieldMapping.rateDate.label.' + (i +1)) }}</label>
                    <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="font-c3"
                               *ngIf="
                             this.form.get('financialAssistance.firstYear.rates.' + i + '.date')?.touched &&
                              this.form.get('financialAssistance.firstYear.rates.' + i + '.date')?.invalid">{{t('error.required')}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>
        <mat-error class="multiple-field-error" *ngIf="this.validationService.validateSponsoringRatesTotal(form, 'firstYear')">
          <p class="font-c2">Die Raten stimmen nicht mit der Gesamtsumme überein. Bitte passen Sie die Raten so an, dass sie die Gesamtsumme ergeben.</p>
        </mat-error>
      </ng-container>

        <!-- Two Years -->
        <ng-template #twoYears>
          <ng-container class="col-lg-12">
            <ng-container formGroupName="firstYear">
              <div class="element-space">
                <p class="font-c2">Sponsoringsumme </p>
                <p class="font-c2s">{{getSponsoringValue('firstYear')}}€</p>
              </div>
              <p class="font-c2">Wie wird die Sponsoringsumme {{this.form.get('sponsoringValue.sponsoringValueGrossAmountFirstYear')?.value}}€ im 1. Jahr gezahlt?</p>
              <mat-radio-group formControlName="fullPayed" (change)="changePaymentMethod($event, 'secondYear', 1)">
                <mat-radio-button [value]="true"><p>{{ t('wizard.financial.payment.full') }}</p></mat-radio-button>
                <mat-radio-button [value]="false"><p>{{ t('wizard.financial.payment.rate') }}</p></mat-radio-button>
              </mat-radio-group>

              <div class="col-lg-12">
                <mat-form-field appearance="fill" class="col-lg-1" *ngIf="!this.form.get('financialAssistance.firstYear.fullPayed')?.value">
                  <mat-select [value]="this.form.get('financialAssistance.firstYear.rateAmount')?.value" (selectionChange)="onSelectRate($event.value, 'secondYear')">
                    <mat-option *ngFor="let rate of rates" [value]="rate">
                      {{ rate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div formArrayName="rates">
                <div *ngFor="let control of listOfRatesFirstYear.controls; let i = index" [formGroupName]="i">
                  <div class="col-lg-12 two-element-row">
                    <mat-form-field class="col-lg-6">
                      <label class="font-c2">{{ t('formFieldMapping.rate.label.' + (i +1)) }}</label>
                      <input placeholder="xxx,xx €" matInput formControlName="value">
                    </mat-form-field>

                    <mat-form-field class="col-lg-6">
                      <label class="font-c2">{{ t('formFieldMapping.rateDate.label.' + (i +1)) }}</label>
                      <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container formGroupName="secondYear">
              <div class="element-space">
                <p class="font-c2">Sponsoringsumme </p>
                <p class="font-c2s">{{getSponsoringValue('secondYear')}}€</p>
              </div>

              <p class="font-c2">Wie wird die Sponsoringsumme {{this.form.get('sponsoringValue.sponsoringValueGrossAmountSecondYear')?.value}}€  im 2. Jahr gezahlt?</p>
              <mat-radio-group formControlName="fullPayed" (change)="changePaymentMethod($event, 'secondYear', 2)">
                <mat-radio-button [value]="true"><p>{{ t('wizard.financial.payment.full') }}</p></mat-radio-button>
                <mat-radio-button [value]="false"><p>{{ t('wizard.financial.payment.rate') }}</p></mat-radio-button>
              </mat-radio-group>

              <div class="col-lg-12">
                <mat-form-field appearance="fill" class="col-lg-1" *ngIf="!this.form.get('financialAssistance.secondYear.fullPayed')?.value">
                  <mat-select [value]="this.form.get('financialAssistance.secondYear.rateAmount')?.value" (selectionChange)="onSelectRate($event.value, 'secondYear')">
                    <mat-option *ngFor="let rate of rates" [value]="rate">
                      {{ rate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div formArrayName="rates">
                <div *ngFor="let control of listOfRatesSecondYear.controls; let i = index" [formGroupName]="i">
                  <div class="col-lg-12 two-element-row">
                    <mat-form-field class="col-lg-6">
                      <label class="font-c2">{{ t('formFieldMapping.rate.label.' + (i +1)) }}</label>
                      <input type="number" placeholder="xxx,xx €" matInput formControlName="value">
                      <mat-error class="font-c3"
                                 *ngIf="this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.touched &&
                this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.invalid">{{t('error.required')}}
                      </mat-error>
                    </mat-form-field>


                    <mat-form-field class="col-lg-6">
                      <label class="font-c2">{{ t('formFieldMapping.rateDate.label.' + (i +1)) }}</label>
                      <input placeholder="TT-MM-YYYY" matInput [matDatepicker]="picker" formControlName="date">
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error class="font-c3"
                                 *ngIf="this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.touched &&
                this.form.get('financialAssistance.secondYear.rates.' + i + '.value')?.invalid">{{t('error.required')}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <mat-error class="multiple-field-error" *ngIf="this.validationService.validateSponsoringRatesTotal(form, 'secondYear')">
            <p class="font-c2">Die Raten stimmen nicht mit der Gesamtsumme überein. Bitte passen Sie die Raten so an, dass sie die Gesamtsumme ergeben.</p>
          </mat-error>
        </ng-template>
      </ng-container>



      <!-- Other parts -->
      <app-zuschuss></app-zuschuss>
      <ng-container id="repetition">
        <h3 class="subtitle">{{ t('wizard.financial.subtitle3') }}</h3>
        <ng-container id="repetition-radio" class="row">
          <div class="col-lg-12">
            <p class="font-c2">{{ t('wizard.financial.repeat.repetition') }}</p>

            <mat-radio-group class="col-lg-12" formControlName="repetition" (change)="changeRepetition($event)">
              <mat-radio-button [value]="true"><p>{{ t('common.yes') }}</p></mat-radio-button>
              <mat-radio-button [value]="false"><p>{{ t('common.no') }}</p></mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-12">
            <mat-form-field class="col-lg-4" *ngIf="this.form.get('financialAssistance.repetition')?.value">
              <label class="font-c2">{{t('wizard.financial.repeat.date')}}</label>
              <input type="number" placeholder="YYYY" matInput [formControlName]="'repetitionYear'">
              <mat-error
                *ngIf="this.validationService.isFieldInvalid('financialAssistance.repetitionYear', form)">
                {{t('error.required')}}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>

    <mat-error></mat-error>
    <mat-error></mat-error>
  </div>

  <app-wizard-footer [newForm]="form"
                     [splitForm]="
                     this.validationService.isFinancialAssistanceInvalid(form,
                     this.form.get('sponsoringValue.sponsoringDuration')?.value === 1, true)

">
  </app-wizard-footer>
</ng-container>
