<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <div class="container-wizard">
    <app-wizard-header [title]="t('wizard.sponsoringStart.title')"></app-wizard-header>

    <div id="wizard-sponsoring-start-date">
      <div class="col-lg-12 error-container ">
        <mat-error class="sectionSpaceDefault font-c3 col-lg-8 multiple-field-error" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringStartDate', form)"><h5>{{t('error.requiredDate')}}</h5></mat-error>
      </div>
      <div class="container">
        <div class="col-lg-8 col-md-8 col-sm-8">
          <mat-card class="card">
            <p class="font-c2 text-center" [innerHTML]="setDatePickerDescriptionText()"></p>
            <div class="col-md-8 col-lg-8 col-sm-8">
              <mat-calendar [selected]="form.get('sponsoringValue.sponsoringStartDate')?.value"
                            (selectedChange)="updateFormDate($event)"
                            [minDate]="currentDate">
              </mat-calendar>
            </div>
          </mat-card>
        </div>
      </div>
      <app-wizard-footer [newForm]="form" [splitForm]="[form.get('sponsoringValue.sponsoringStartDate')]"></app-wizard-footer>
    </div>
  </div>
</ng-container>
