<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.sponsored.title')"></app-wizard-header>
    <div formGroupName="sponsored">
      <div class="container">

        <mat-form-field class="full-with">
          <label class="font-c2">{{t('formFieldMapping.sponsoredName')}}</label>
          <input  matInput [formControlName]="'sponsoredName'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.sponsoredName', form)">{{t('error.required')}}</mat-error>
        </mat-form-field>

        <mat-form-field class="full-with">
          <label class="font-c2">{{t('formFieldMapping.responsibleDepartment')}}</label>
          <input matInput [formControlName]="'responsibleDepartment'">
        </mat-form-field>

        <mat-form-field class="full-with">
          <label class="font-c2">{{t('formFieldMapping.responsiblePersonal')}}</label>
          <input matInput [formControlName]="'responsiblePersonal'">
        </mat-form-field>

        <div class="col-lg-12 two-element-row">
          <mat-form-field class="col-lg-10">
            <label class="font-c2">{{t('formFieldMapping.street_name')}}</label>
            <input matInput [formControlName]="'street_name'">
            <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.street_name', form)">{{t('error.required')}}</mat-error>
          </mat-form-field>

          <mat-form-field class="col-lg-2">
            <label class="font-c2">{{t('formFieldMapping.street_number')}}</label>
            <input matInput [formControlName]="'street_number'">
            <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.street_number', form)">{{t('error.required')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-12 two-element-row">
          <mat-form-field class="col-lg-2">
            <label class="font-c2">{{t('formFieldMapping.zip')}}</label>
            <input matInput [formControlName]="'zip'">
            <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.zip', form)">{{t('error.required')}}</mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-10">
            <label class="font-c2">{{t('formFieldMapping.city')}}</label>
            <input matInput [formControlName]="'city'">
            <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.city', form)">
              {{form.get('sponsored.zip')?.hasError('required') ? t('error.required') : t('error.postalCodeInvalid')}}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="full-with">
          <label class="font-c2">{{t('formFieldMapping.website')}}</label>
          <input  matInput [formControlName]="'website'">
          <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.website', form)">
            {{t('error.websiteFormat')}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-with">
          <label class="font-c2">{{t('formFieldMapping.jobDescription')}}</label>
          <textarea [minlength]="10"
                    placeholder="min. 10 Zeichen"
                    matInput [formControlName]="'jobDescription'"></textarea>
          <mat-error *ngIf="this.validationService.isFieldInvalid('sponsored.city', form)">
            {{form.get('sponsored.jobDescription')?.hasError('required') ? t('error.required') : t('error.min')}}
          </mat-error>
        </mat-form-field>

        <app-hint
          [hintDescription]="t('wizard.sponsored.hint.content')"
          [additionalTitle]="t('wizard.sponsored.hint.addition.title')"
          [additionalContent]="t('wizard.sponsored.hint.addition.content')">
        </app-hint>
      </div>
    </div>
  <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isSponsoredInvalid(form)"></app-wizard-footer>
</ng-container>
