<ng-container *transloco="let t">
  <app-stepper></app-stepper>
  <div class="container-wizard">
    <app-wizard-header [title]="t('wizard.upload.title')" [subtitle]="t('wizard.upload.description')"></app-wizard-header>
    <div class="container" id="data-upload">
      <app-hint [hintDescription]="t('wizard.upload.hint')"></app-hint>



      <app-file-viewer [purpose]="['sonstiges_antrag']"></app-file-viewer>
      <app-file-uploader (filesSelected)="uploadAllFiles($event) " [purpose]="'sonstiges_antrag'"></app-file-uploader>

    </div>
    <app-wizard-footer [newForm]="form" [splitForm]="[]"></app-wizard-footer>
  </div>
</ng-container>
