import {Component, Input} from '@angular/core';
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {
  MatCellDef,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource,
  MatTableModule
} from "@angular/material/table";
import {DatePipe, NgClass} from "@angular/common";
import {Router} from "@angular/router";
import {
  ApplicationStateComponentComponent
} from "../../../shared/components/application-state-component/application-state-component.component";
import {ApplicationDto} from "../../../api/applicationDto";
import {AuthenticationService} from "../../../core/services/authentication.service";

@Component({
  selector: 'app-application-overview-table',
  standalone: true,
  imports: [
    IconsComponent,
    TranslocoDirective,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatTableModule,
    DatePipe,
    NgClass,
    ApplicationStateComponentComponent,
  ],
  templateUrl: './application-overview-table.component.html',
  styleUrl: './application-overview-table.component.scss'
})


export class ApplicationOverviewTableComponent {
  @Input()
  set allApplications(allApplications: ApplicationDto[]) {
    this._allApplications = allApplications;
    this.initializeDataSource();
  }
  get allApplications(): ApplicationDto[] {
    return this._allApplications;
  }

  private _allApplications: ApplicationDto[] = [];

  displayedColumns: string [] = ['application', 'date', 'state'];
  dataSource = new MatTableDataSource<ApplicationDto>();

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.initializeDataSource();
  }

  initializeDataSource(): void {
    this.dataSource = new MatTableDataSource(this.allApplications)
  }

  editApplication(row: any) {
    this.router.navigate(['/detailview'], {state: {applicationId: row.id}});
  }
}
