import {Component, Input} from '@angular/core';
import {translate, TranslocoModule} from "@ngneat/transloco";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {Router, RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {UserRoleEnum} from "../../../api/enum/userRoleEnum";

@Component({
  selector: 'app-add-new-application',
  standalone: true,
  imports: [TranslocoModule, IconsComponent, IconTextButtonComponent, NgIf, MatCard, RouterLink],
  templateUrl: './add-new-application.component.html',
  styleUrl: './add-new-application.component.scss'
})
export class AddNewApplicationComponent {
  @Input() emptyDashboard = true;

  constructor(private router: Router, public authenticationService: AuthenticationService) {
  }

  addNewApplication(): void {
    this.router.navigate(['/duration']);
  }

  getTitle(): string {
    const userRole = '';

/*
    switch (userRole) {
      case UserRoleEnum.APPLICANT:
        return translate('dashboard.emptyDashboard.placeholder.title');
      case UserRoleEnum.VKF:
        return translate('dashboard.emptyDashboardVkf.title');
      default:
        return '';
    }*/
    return 'any'
  }


  getDescription(): string {/*
    const userRole = '';
    switch (userRole) {
      case UserRoleEnum.APPLICANT:
        return translate('dashboard.emptyDashboard.placeholder.description');
      case UserRoleEnum.VKF:
        return translate('dashboard.emptyDashboardVkf.description');
      default:
        return '';
    }*/
    return 'any'
  }

  protected readonly UserRoleEnum = UserRoleEnum;
}
