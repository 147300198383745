<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.sponsoringStart.title')"></app-wizard-header>

  <div id="wizard-sponsoring-start-date" class="row">
    <div class="container">
      <div class="col-lg-8 col-md-8 col-sm-8">
        <mat-card class="card">
          <p class="font-c2 text-center"> {{setDatePickerDescriptionText()}}</p>
          <div class="col-md-8 col-lg-8 col-sm-8">
            <mat-calendar [selected]="form.get('sponsoringValue.sponsoringStartDate')?.value"
                          (selectedChange)="updateFormDate($event)"
                          [minDate]="currentDate">
            </mat-calendar>
          </div>
        </mat-card>
      </div>
    </div>
    <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringStartDate', form)">{{t('error.requiredDate')}}</mat-error>
  </div>
  <app-wizard-footer [newForm]="form" [splitForm]="[form.get('sponsoringValue.sponsoringStartDate')]"></app-wizard-footer>
</ng-container>
