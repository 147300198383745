import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[appFormControlError]'
})
export class FormErrorDirective implements OnInit {
  @Input() customErrorMessages: { [key: string]: string } = {};
  @Input() submitted = false;

  constructor(private ngControl: NgControl, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const control = this.ngControl.control;
    if (control) {
      control.statusChanges?.subscribe(() => {
        this.setErrorMessages(control);
      });
    }
  }

  private setErrorMessages(control: AbstractControl) {
    const container = this.el.nativeElement.parentNode;
    let errorContainer = container.querySelector('.form-error-message');

    if (!control.invalid || (!control.dirty && !control.touched && !this.submitted)) {
      if (errorContainer) {
        this.renderer.removeChild(container, errorContainer);
      }
      return;
    }

    if (!errorContainer) {
      errorContainer = this.renderer.createElement('div');
      this.renderer.addClass(errorContainer, 'form-error-message');
      this.renderer.appendChild(container, errorContainer);
    }

    let errorMessage = '';

    if (control.errors) {
      if (control.errors['required']) {
        errorMessage = this.customErrorMessages['required'] || 'Dieses Feld ist erforderlich';
      } else if (control.errors['email']) {
        errorMessage = this.customErrorMessages['email'] || 'Ungültige E-Mail-Adresse';
      } else if (control.errors['minlength']) {
        errorMessage = this.customErrorMessages['minlength'] || `Mindestens ${control.errors['minlength'].requiredLength} Zeichen erforderlich`;
      } else if (control.errors['maxlength']) {
        errorMessage = this.customErrorMessages['maxlength'] || `Maximal ${control.errors['maxlength'].requiredLength} Zeichen erlaubt`;
      } else if (control.errors['pattern']) {
        errorMessage = this.customErrorMessages['pattern'] || 'Ungültiges Format';
      }
    }

    this.renderer.setProperty(errorContainer, 'innerHTML', errorMessage);
  }
}
