import { Component, Input } from '@angular/core';
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import { IconsComponent } from '../icons/icons.component';
import {FormService} from "../../services/form-service/form.service";
import {BytesToSizePipe} from "../../pipes/bytesToSize.pipe";
import {HttpClient} from "@angular/common/http";
import {FileUploadService} from "../file-uploader/file-uploader-service";

@Component({
  selector: 'app-file-viewer',
  standalone: true,
  imports: [NgIf, IconsComponent, NgForOf, DatePipe, BytesToSizePipe],
  templateUrl: './file-viewer.component.html',
  styleUrl: './file-viewer.component.scss',
})
export class FileViewerComponent {
  @Input() selectedFiles: File[] = [];
  form = this.formService.buildForm();
  allFiles = this.fileUploadService.getData();

  constructor(private formService: FormService, private http: HttpClient, public fileUploadService: FileUploadService) {
    this.updateFiles();
  }

  private updateFiles(): void {
    const savedFiles = this.form.get('dataUpload')?.value;
  }

  downloadFile(fileMetadata: any) {
    // Assume the API endpoint to fetch the file data
    const fileUrl = `https://your-api-endpoint.com/files/${fileMetadata.name}`;

    this.http.get(fileUrl, { responseType: 'blob' })
      .subscribe(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileMetadata.name;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      });
  }
}
