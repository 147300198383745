import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {DataService} from "../../../core/services/data.service";
import {FileUploadService} from "../../components/file-uploader/file-uploader-service";

@Injectable({
  providedIn: 'root'
})
export class FormService {
  formData = this.buildForm();

  constructor(private fb: FormBuilder,
              private dataService: DataService, private fileUploadService: FileUploadService) {}

  buildForm(): FormGroup {
    return this.fb.group({
      sponsoringValue: this.fb.group({
        application_state: [],
        funding_amount: [],
        id: [],
        sponsoringDuration: [undefined, Validators.required],
        taxFree: [false, Validators.required],
        sponsoringStartDate: [null, Validators.required],
        sponsoringEndDate: [null, Validators.required],
        sponsoringValueGrossAmountFirstYear: [null, Validators.required],
        sponsoringValueNetAmountFirstYear: [null, Validators.required],
        sponsoringValueGrossAmountSecondYear: [null, Validators.required],
        sponsoringValueNetAmountSecondYear: [null, Validators.required],
      }),
      applicant: this.fb.group({
        rd_number: [null, [Validators.required, this.maxDigits(5)]],
        personal_number: [null, Validators.required],
        career_level: [null, Validators.required],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        street_name: [null, Validators.required],
        street_number: [null, Validators.required],
        zip: [null, [Validators.required, this.postalCodeValidator()]],
        city: [null, Validators.required],
        email: [null, [Validators.required, this.emailValidator()]],
        telephone: [null, Validators.required],
      }),
      sponsored: this.fb.group({
        sponsoredName: [null, Validators.required],
        responsibleDepartment: [null],
        street_name: [null, Validators.required],
        street_number: [null, Validators.required],
        zip: [null, [Validators.required, this.postalCodeValidator()]],
        city: [null, Validators.required],
        website: [null, this.websiteValidator()],
        jobDescription: [null, Validators.required],
        responsiblePersonal: [null],
      }),
      financialAssistance: this.fb.group({
        firstYear: this.fb.group({
          fullPayed: [false, Validators.required],
          rateAmount: [null, Validators.required],
          rates: this.fb.array([]),
        }),
        secondYear: this.fb.group({
          fullPayed: [false, Validators.required],
          rateAmount: [null, Validators.required],
          rates: this.fb.array([]),
        }),
        zuschuss: [false, Validators.required],
        repetition: [false, Validators.required],
        repetitionYear: [null, [Validators.required, this.maxMinDigits(4, 4)]],
      }),
      entitlements: this.fb.group({
        services:this.fb.array([]),
        hospitality: this.fb.group({
          isHospitality: [false, Validators.required],
          hospitalityService: [null, [Validators.required, Validators.minLength(10)]],
          hospitalityValue: [null, Validators.required],
        }),
        titleRight: [null, Validators.required],
        exclusivity: [false, Validators.required],
      }),
      consent: this.fb.group({
        goal: [false, this.mustBeTrueValidator()],
        reputation: [false, this.mustBeTrueValidator()],
        religion: [false, this.mustBeTrueValidator()],
        discrimination: [false, this.mustBeTrueValidator()],
        politics: [false, this.mustBeTrueValidator()],
        law: [false, this.mustBeTrueValidator()],
        balance: [false, this.mustBeTrueValidator()],
        fix: [false, this.mustBeTrueValidator()],
        affectionPerson: [false, this.mustBeTrueValidator()],
        affectionEmployee: [false, this.mustBeTrueValidator()],
        gremium: [false],
        consentUsingRegulationRules: [false, this.mustBeTrueValidator()],
        consentUsingHint: [false, this.mustBeTrueValidator()],
        currentSponsoringRules: [false, this.mustBeTrueValidator()],
        information: [false, this.mustBeTrueValidator()],
      }),
      dataUpload: this.fb.array([]),
      dataGenerated: this.fb.array([])
    });
  }
  commentForm = this.fb.group({
    sponsoringValue: this.fb.group({
      comment: [null],
    }),
      applicant: this.fb.group({
      comment: [null],
    }),
      sponsored: this.fb.group({
      comment: [null],
    }),
      financialAssistance: this.fb.group({
      comment: [null],
    }),
      entitlements: this.fb.group({
      comment: [null],
    }),
      dataUpload: this.fb.group({
        comment: [null],
      })
  });

  setFormData(data: any): void {
    localStorage.setItem('form-data', JSON.stringify(data.value));
  }

  getFormData(): any {
    const localStorageData = localStorage.getItem('form-data');
    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData);
        return parsedData;
      } catch (e) {
        localStorage.removeItem('form-data');
      }
    }
    return this.formData.value;
  }

  removeFormData(): void {
    localStorage.removeItem('form-data');
  }

  createNewServiceForm(value = ''): FormControl {
    return this.fb.control(value, [Validators.required]);
  }

  createNewRateForm(value = {}): FormGroup {
    return this.fb.group({
      value: new FormControl<number | null>(null, [Validators.required]),
      date: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  initializeForm(newForm: FormGroup): any {
    const formData = this.getFormData();

    const services = newForm.get('entitlements.services') as FormArray;
    const ratesFirstYear = newForm.get('financialAssistance.firstYear.rates') as FormArray;
    const ratesSecondYear = newForm.get('financialAssistance.secondYear.rates') as FormArray;

    formData?.entitlements?.services?.forEach((service:string) => {
      services?.push(this.createNewServiceForm(service));
    });
    formData?.financialAssistance?.firstYear?.rates.forEach((rate: any) => {
      ratesFirstYear?.push(this.createNewRateForm(rate));
    });
    formData?.financialAssistance?.secondYear?.rates.forEach((rate: any) => {
      ratesSecondYear?.push(this.createNewRateForm(rate));
    });

    return newForm.patchValue(formData);
  }

  // todo muss refactored werden this.formService.initializeFormFromData(
  initializeFormFromData(newForm: any): any {
    if (newForm) {

      // Sponsoring Value
      const sponsoringValue = this.formData.get('sponsoringValue') as FormGroup;
      sponsoringValue.get('application_state')?.setValue(newForm.sponsoringValue?.application_state);
      sponsoringValue.get('funding_amount')?.setValue(newForm.sponsoringValue?.funding_amount);
      sponsoringValue.get('id')?.setValue(newForm.sponsoringValue?.id);
      sponsoringValue.get('sponsoringDuration')?.setValue(newForm.sponsoringValue?.sponsoringDuration);
      sponsoringValue.get('taxFree')?.setValue(newForm.sponsoringValue?.taxFree);
      sponsoringValue.get('sponsoringStartDate')?.setValue(newForm.sponsoringValue?.sponsoringStartDate);
      sponsoringValue.get('sponsoringEndDate')?.setValue(newForm.sponsoringValue?.sponsoringEndDate);
      sponsoringValue.get('sponsoringValueGrossAmountFirstYear')?.setValue(newForm.sponsoringValue?.sponsoringValueGrossAmountFirstYear);
      sponsoringValue.get('sponsoringValueNetAmountFirstYear')?.setValue(newForm.sponsoringValue?.sponsoringValueNetAmountFirstYear);
      sponsoringValue.get('sponsoringValueGrossAmountSecondYear')?.setValue(newForm.sponsoringValue?.sponsoringValueGrossAmountSecondYear);
      sponsoringValue.get('sponsoringValueNetAmountSecondYear')?.setValue(newForm.sponsoringValue?.sponsoringValueNetAmountSecondYear);

      // Applicant
      const applicant = this.formData.get('applicant') as FormGroup;
      applicant.get('career_level')?.setValue(newForm.applicant?.career_level);
      applicant.get('city')?.setValue(newForm.applicant?.city);
      applicant.get('email')?.setValue(newForm.applicant?.email);
      applicant.get('firstName')?.setValue(newForm.applicant?.firstName);
      applicant.get('lastName')?.setValue(newForm.applicant?.lastName);
      applicant.get('personal_number')?.setValue(newForm.applicant?.personal_number);
      applicant.get('rd_number')?.setValue(newForm.applicant?.rd_number);
      applicant.get('street_name')?.setValue(newForm.applicant?.street_name);
      applicant.get('street_number')?.setValue(newForm.applicant?.street_number);
      applicant.get('telephone')?.setValue(newForm.applicant?.telephone);
      applicant.get('zip')?.setValue(newForm.applicant?.zip);

      // Sponsored
      const sponsored = this.formData.get('sponsored') as FormGroup;
      sponsored.get('sponsoredName')?.setValue(newForm.sponsored?.sponsoredName);
      sponsored.get('responsibleDepartment')?.setValue(newForm.sponsored?.responsibleDepartment);
      sponsored.get('street_name')?.setValue(newForm.sponsored?.street_name);
      sponsored.get('street_number')?.setValue(newForm.sponsored?.street_number);
      sponsored.get('zip')?.setValue(newForm.sponsored?.zip);
      sponsored.get('city')?.setValue(newForm.sponsored?.city);
      sponsored.get('website')?.setValue(newForm.sponsored?.website);
      sponsored.get('jobDescription')?.setValue(newForm.sponsored?.jobDescription);
      sponsored.get('responsiblePersonal')?.setValue(newForm.sponsored?.responsiblePersonal);

      // Financial Assistance
      const financialAssistance = this.formData.get('financialAssistance') as FormGroup;
      const firstYear = financialAssistance.get('firstYear') as FormGroup;
      console.log(newForm, '**new');
      firstYear.get('fullPayed')?.setValue(newForm.financialAssistance?.firstYear?.fullPayed);
      firstYear.get('rateAmount')?.setValue(newForm.financialAssistance?.firstYear?.rateAmount);
      // Handle rates array for firstYear
      const firstYearRates = firstYear.get('rates') as FormArray;
      this.setFormArrayValues(firstYearRates, newForm.financialAssistance?.firstYear?.rates || []);

      const secondYear = financialAssistance.get('secondYear') as FormGroup;
      secondYear.get('fullPayed')?.setValue(newForm.financialAssistance?.secondYear?.fullPayed);
      secondYear.get('rateAmount')?.setValue(newForm.financialAssistance?.secondYear?.rateAmount);
      // Handle rates array for secondYear
      const secondYearRates = secondYear.get('rates') as FormArray;
      this.setFormArrayValues(secondYearRates, newForm.financialAssistance?.secondYear?.rates || []);

      financialAssistance.get('zuschuss')?.setValue(newForm.financialAssistance?.zuschuss);
      financialAssistance.get('repetition')?.setValue(newForm.financialAssistance?.repetition);
      financialAssistance.get('repetitionYear')?.setValue(newForm.financialAssistance?.repetitionYear);

      // Entitlements
      const entitlements = this.formData.get('entitlements') as FormGroup;
      const services = entitlements.get('services') as FormArray;
      this.setFormArrayValues(services, newForm.entitlements?.services || []);

      const hospitality = entitlements.get('hospitality') as FormGroup;
      hospitality.get('isHospitality')?.setValue(newForm.entitlements?.hospitality?.isHospitality);
      hospitality.get('hospitalityService')?.setValue(newForm.entitlements?.hospitality?.hospitalityService);
      hospitality.get('hospitalityValue')?.setValue(newForm.entitlements?.hospitality?.hospitalityValue);

      entitlements.get('titleRight')?.setValue(newForm.entitlements?.titleRight);
      entitlements.get('exclusivity')?.setValue(newForm.entitlements?.exclusivity);

      // Consent
      const consent = this.formData.get('consent') as FormGroup;
      consent.get('goal')?.setValue(newForm.consent?.goal);
      consent.get('reputation')?.setValue(newForm.consent?.reputation);
      consent.get('religion')?.setValue(newForm.consent?.religion);
      consent.get('discrimination')?.setValue(newForm.consent?.discrimination);
      consent.get('politics')?.setValue(newForm.consent?.politics);
      consent.get('law')?.setValue(newForm.consent?.law);
      consent.get('balance')?.setValue(newForm.consent?.balance);
      consent.get('fix')?.setValue(newForm.consent?.fix);
      consent.get('affectionPerson')?.setValue(newForm.consent?.affectionPerson);
      consent.get('affectionEmployee')?.setValue(newForm.consent?.affectionEmployee);
      consent.get('gremium')?.setValue(newForm.consent?.gremium);
      consent.get('consentUsingRegulationRules')?.setValue(newForm.consent?.consentUsingRegulationRules);
      consent.get('consentUsingHint')?.setValue(newForm.consent?.consentUsingHint);
      consent.get('currentSponsoringRules')?.setValue(newForm.consent?.currentSponsoringRules);
      consent.get('information')?.setValue(newForm.consent?.information);

      // Data Upload and Data Generated
      const dataUpload = this.formData.get('dataUpload') as FormArray;
      this.setFormArrayValues(dataUpload, newForm.dataUpload || []);

      const dataGenerated = this.formData.get('dataGenerated') as FormArray;
      this.setFormArrayValues(dataGenerated, newForm.dataGenerated || []);
    }
    return this.formData;
  }


  setFormArrayValues(formArray: FormArray, values: any[]): void {
    if (!Array.isArray(values)) {
      console.error('Expected values to be an array, but got:', values);
      return;
    }
    formArray.clear();
    values.forEach(value => {
      formArray.push(this.fb.control(value));
    });
  }




  // todo auslagern in validatorservice
  private emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const hasAtSymbol = value && value.includes('@');
      return hasAtSymbol ? null : { 'emailInvalid': true };
    };
  }

  private postalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const valid = /^\d{5}$/.test(value);
      return valid ? null : { 'postalCodeInvalid': true };
    };
  }

  private maxDigits(digitNumber: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const stringValue = value ? value.toString() : '';
      const valid = stringValue.length <= digitNumber && /^\d*$/.test(stringValue);
      return valid ? null : { 'maxDigits': true };
    };
  }

  private maxMinDigits(max: number, min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const stringValue = value ? value.toString() : '';
      const valid = stringValue.length >= min && stringValue.length <= max && /^\d*$/.test(stringValue);
      return valid ? null : { 'invalidDigitLength': true };
    };
  }



  private mustBeTrueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      return value ? null : { 'mustBeTrue': true };
    };
  }

  private websiteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;

      if (!value) {
        return null;
      }
      return value.startsWith('www.') ? null : { 'websiteFormat': true };
    };
  }
}
