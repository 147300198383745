<ng-container *transloco="let t" >
  <div id="dashboard-media" class="row">
    <div class="mediaContent col-md-12 col-sm-12 col-lg-12">
      <div class="leftPart col-md-7 col-sm-7 col-lg-7">
        <div class="video-container col-md-5 col-sm-5 col-lg-5 box-margin-right">
          <app-media-box  [isVideo]="true" [videoName]="'how-to.mp4'"></app-media-box>
        </div>
        <div class="box-margin-right col-md-5 col-sm-5 col-lg-5">
          <app-media-box [isPdf]="true"></app-media-box>
        </div>
      </div>
      <div class="rightPart col-md-5 col-sm-5 col-lg-5">
        <div class="helpPart col-md-7 col-sm-7 col-lg-7">
          <app-media-box [isText]="true" [textContent]="mediaBoxContent"></app-media-box>
        </div>
      </div>
    </div>
  </div>

  <ng-template #mediaBoxContent>
    <app-icons [iconColor]="'red'" [iconSize]="'large'" [iconName]="'question-icon'"></app-icons>
    <h5>{{t('dashboard.mediaPart.helpTitle')}}</h5>
    <p class="font-c3">{{t('dashboard.mediaPart.helpDescription')}}</p>
  </ng-template>
</ng-container>
