import {Component, OnInit} from '@angular/core';
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";
import {
  FinancialAssistanceKeyValueComponent
} from "../../../shared/components/financial-assistance-table-component/financial-assistance-key-value-component";
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../../sponsoring-wizard/wizard-header/wizard-header.component";
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AbstractControl,
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {IconTextButtonComponent} from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import {MatCard} from "@angular/material/card";
import {StateTextService} from "../../../shared/services/state-text-service/state-text.service";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {MatError} from "@angular/material/form-field";


@Component({
  selector: 'app-vkf-detailoverview',
  standalone: true,
  imports: [
    FileUploaderComponent,
    FinancialAssistanceKeyValueComponent,
    KeyValueCardComponentComponent,
    StepperComponent,
    TextButtonComponent,
    TranslocoDirective,
    WizardHeaderComponent,
    ReactiveFormsModule,
    HintComponent,
    IconTextButtonComponent,
    MatCard,
    NgForOf,
    NgIf,
    DatePipe,
    FormsModule,
    FileViewerComponent,
    MatError,
  ],
  templateUrl: './vkf-detailoverview.component.html',
  styleUrl: './vkf-detailoverview.component.scss'
})
export class VkfDetailoverviewComponent implements OnInit{
  form = this.formService.buildForm();
  internalNote = '';
  comments: { [key: string]: Array<{ author: string; date: string; sonstiges: string }> } = {};
  editMode = false;
  applicationId = '';
  applicationData: any = null;

  sections = [
    { id: 'sponsoringValue', title: 'keyValueTitles.sponsoringValue', dataKey: 'sponsoringValue' },
    { id: 'applicant', title: 'keyValueTitles.applicant', dataKey: 'applicant' },
    { id: 'sponsored', title: 'keyValueTitles.sponsored', dataKey: 'sponsored' },
    { id: 'financialAssistance', title: 'keyValueTitles.financialAssistance', dataKey: 'financialAssistance' },
    { id: 'entitlements', title: 'keyValueTitles.entitlements', dataKey: 'entitlements' },
    { id: 'dataUpload', title: 'keyValueTitles.dataUpload', dataKey: 'dataUpload' }
  ];

  constructor(public wizardService: WizardService,
              public stateTextService: StateTextService,
              public formService: FormService,
              public dataService: DataService,
              public router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
  ) {
    /*this.form = this.fb.group({
      comments: this.fb.group({
        applicant: this.fb.control(''),
        sponsoringValue: this.fb.control(''),
        financialAssistance: this.fb.control(''),
        entitlements: this.fb.control(''),
        sponsored: this.fb.control(''),
        dataUpload: this.fb.control('')
      })
    });*/
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if(state.applicationId){
        this.applicationId = state.applicationId.toString();
        this.getDataForSelectedApplication(state.applicationId);
        this.getCommentsForSelectedApplication(state.applicationId);
      }
    });
  }

  isButtonPrimary(index: number): boolean {
    const buttons = this.stateTextService.returnTextForBox(this.applicationData?.sponsoringValue?.application_state).button;
    return buttons.length <= 1 || index !== 0;
  }


  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.applicationData = result;
      console.log(result, '**resiöt');
      this.form.patchValue(result);
      console.log(this.form);
    });
  }

  getCommentsForSelectedApplication(applicationId: string): void {
    this.dataService.getCommentsForApplicationById(applicationId).subscribe((result) => {
      this.comments = result;
    });
  }

  getCommentsForSection(section: string): Array<{ authorName: string; created_at: string; content: string }> | null {
    let result: any;
    if(this.comments['comments']){
      Object.entries(this.comments['comments']).forEach(([key, value]) => {
        if(key === section){
          result = value;
        }
      });
    }
    return result;
  }


  postComment(): object  {
    const postCommentRequest = {
      applicant: {
        comments: [this.form.get('comments.applicant')?.value],
      },
      sponsoringValue: {
        comments: [this.form.get('comments.sponsoringValue')?.value],
      },
      financialAssistance: {
        comments: [this.form.get('comments.financialAssistance')?.value],
      },
      entitlements: {
        comments: [this.form.get('comments.entitlements')?.value],
      },
      sponsored: {
        comments: [this.form.get('comments.sponsored')?.value],
      },
      dataUpload: {
        comments: [this.form.get('comments.dataUpload')?.value],
      },
    }
    return postCommentRequest
  }

  changeStateToCorrectionsNeeded(action: string): void {
  this.dataService.postComment(this.applicationId, this.postComment()).subscribe({
    next: ()  => {
      this.dataService.changeStateOfApplication(this.applicationId, action, '').subscribe({
        next: () => {
          this.navigateToDashboard();
        },
        error: (err) => {
          console.error('Status konnte nicht geändert werden', err);
        }
      });
    },
    error: (err) => {
      console.error('An error occurred:', err);
    }
  });
  }

  saveInteranlNote(note: string): void {
    this.dataService.postInternalNotes(this.applicationId, {note}).subscribe({
      next: () => {
        this.internalNote = ''
        this.getDataForSelectedApplication(this.applicationId);
      },
      error: (err) => {
        console.error('Interne Anmerkung konnte nicht geändert werden', err);
      }
    });
  }


  switchMode(disabledButton: boolean) {
    if(!disabledButton){
      this.editMode = !this.editMode;
      /** wieso */
      /*
      this.dataService.changeStateOfApplication(this.applicationId, 'accepted', '').subscribe({
        error: (err) => {
          console.error('Status konnte nicht geändert werden', err);
        }
      });*/
    }
  }
  navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

}
