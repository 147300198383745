<ng-container *transloco="let t">
  <mat-card class="hint">
    <div class="infoContent">
      <div class="col-sm-12 col-md-12 col-lg-12 infoContainer">
        <div class="hintTitle col-lg-12 col-md-12 col-sm-12">
          <app-icons [iconSize]="'medium'" [iconName]="'info-icon'"></app-icons>
          <h5 class="hintSpaceIcon">{{t('hint.text')}}</h5>
        </div>
        <div class="hintContent col-lg-12 col-md-12 col-sm-12">
          <p class="font-c2 hintSpaceText">{{hintDescription}}</p>
        </div>
      </div>
      <div *ngIf="additionalTitle" class="additional col-sm-12 col-md-12 col-lg-12 infoContainer" >
          <h5 class="hintSpaceText">{{additionalTitle}}</h5>
          <p class="font-c2 hintSpaceText">{{additionalContent}}</p>
      </div>
    </div>
  </mat-card>
</ng-container>

