export enum applicationStateEnum {
  NEU = 'Neu',
  OPEN = 'Antrag eingereicht',

  CORRECTION_NEEDED = 'Korrektur erforderlich',
  CORRECTION_VP = 'Korrektur bei VP',
  CORRECTION_CHECK = 'Korrekturprüfung',



  APPROVAL_NO_CONTRACT = 'Genehmigt/Vertrag fehlt',
  APPROVED_ADD_CONTRACT = 'Genehmigt/Vertrag einreichen',
  CONTRACT_ADDED = 'Vertrag eingereicht',
  CONTRACT_CHECK = 'Vertragsprüfung',

  ZUSCHUSS = 'Zuschussprüfung',

  DONE = 'Abgeschlossen',
  REJECTED_RD = 'Abgelehnt durch RD-Leiter',
  REJECTED_OD = 'Abgelehnt durch OD-Leiter',
  REJECTED_GLVKF = 'Abgelehnt durch GLVKF',
  REJECTED_VKF = 'Abgelehnt durch VKF',

  CHECK_RD = 'In Prüfung - RD-Leiter',
  CHECK_OD = 'In Prüfung - Vorprüfer OD',
  CHECK_OD_LEAD = 'In Prüfung - OD-Leiter',
  CHECK_VKF = 'In Prüfung - VKF',
  CHECK_GLVKF = 'In Prüfung - GLVKF',

  CHECK_DONE_VKF = 'Geprüft durch VKF',

  APPROVED_RD_LEITER = "Genehmigt durch RD-Leiter",
  REJECTED_RD_LEITER =  "Abgelehnt durch RD-Leiter",
  APPROVED_GLVKF = "Genehmigt durch GLVKF",
  SPONSORING_APPROVED_VKF =  "Sponsoring Daten genehmigt durch VKF",

  CONTRACT_SIGNED = "Vertrag wurde unterschrieben eingereicht von Antragsteller"
}
