import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {DataService} from "../../../core/services/data.service";
import {FileUploadService} from "../../components/file-uploader/file-uploader-service";

@Injectable({
  providedIn: 'root'
})
export class FormService {
  formData = this.buildForm();

  constructor(private fb: FormBuilder,
              private dataService: DataService, private fileUploadService: FileUploadService) {}

  buildForm(): FormGroup {
    return this.fb.group({
      sponsoringValue: this.fb.group({
        application_state: [],
        funding_amount: [],
        id: [],
        sponsoringDuration: [undefined, Validators.required],
        taxFree: [false, Validators.required],
        sponsoringStartDate: [null, Validators.required],
        sponsoringEndDate: [null, Validators.required],
        sponsoringValueGrossAmountFirstYear: [null, Validators.required],
        sponsoringValueNetAmountFirstYear: [null, Validators.required],
        sponsoringValueGrossAmountSecondYear: [null, Validators.required],
        sponsoringValueNetAmountSecondYear: [null, Validators.required],
      }),
      applicant: this.fb.group({
        career_level: [null, Validators.required],
        city: [null, Validators.required],
        email: [null, [Validators.required, this.emailValidator()]],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        personal_number: [null, Validators.required],
        rd_number: [null, Validators.required],
        street_name: [null, Validators.required],
        street_number: [null, Validators.required],
        telephone: [null, Validators.required],
        zip: [null, [Validators.required, this.postalCodeValidator()]],
      }),
      sponsored: this.fb.group({
        sponsoredName: [null, Validators.required],
        responsibleDepartment: [null],
        street_name: [null, Validators.required],
        street_number: [null, Validators.required],
        zip: [null, [Validators.required, this.postalCodeValidator()]],
        city: [null, Validators.required],
        website: [null, this.websiteValidator()],
        jobDescription: [null, Validators.required],
        responsiblePersonal: [null],
      }),
      financialAssistance: this.fb.group({
        firstYear: this.fb.group({
          fullPayed: [false, Validators.required],
          rates: this.fb.array([]),
          rateAmount: [1, Validators.required],
        }),
        secondYear: this.fb.group({
          fullPayed: [false, Validators.required],
          rates: this.fb.array([]),
          rateAmount: [1, Validators.required],
        }),
        zuschuss: [false, Validators.required],
        repetition: [false, Validators.required],
        repetitionYear: [null, Validators.required],
      }),
      entitlements: this.fb.group({
        services:this.fb.array([]),
        hospitality: this.fb.group({
          isHospitality: [false, Validators.required],
          hospitalityService: [null, [Validators.required, Validators.minLength(10)]],
          hospitalityValue: [null, Validators.required],
        }),
        titleRight: [null, Validators.required],
        exclusivity: [false, Validators.required],
      }),
      consent: this.fb.group({
        goal: [false, this.mustBeTrueValidator()],
        reputation: [false, this.mustBeTrueValidator()],
        discrimination: [false, this.mustBeTrueValidator()],
        law: [false, this.mustBeTrueValidator()],
        politics: [false, this.mustBeTrueValidator()],
        religion: [false, this.mustBeTrueValidator()],
        balance: [false, this.mustBeTrueValidator()],
        fix: [false, this.mustBeTrueValidator()],
        affectionPerson: [false, this.mustBeTrueValidator()],
        affectionEmployee: [false, this.mustBeTrueValidator()],
        consentUsingRegulationRules: [false, this.mustBeTrueValidator()],
        consentUsingHint: [false, this.mustBeTrueValidator()],
        currentSponsoringRules: [false, this.mustBeTrueValidator()],

      }),
      dataUpload: this.fb.array([]),
      dataGenerated: this.fb.array([])
    });
  }

  commentForm = this.fb.group({
    sponsoringValue: this.fb.group({
      comment: [null],
    }),
      applicant: this.fb.group({
      comment: [null],
    }),
      sponsored: this.fb.group({
      comment: [null],
    }),
      financialAssistance: this.fb.group({
      comment: [null],
    }),
      entitlements: this.fb.group({
      comment: [null],
    }),
      dataUpload: this.fb.group({
        comment: [null],
      })
  });

  setFormData(data: any): void {
    if(data?.value){
      localStorage.setItem('form-data', JSON.stringify(data.value));
    } else {
      localStorage.setItem('form-data', JSON.stringify(data));
    }
  }

  getFormData(): any {
    const localStorageData = localStorage.getItem('form-data');
    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData);
        return parsedData;
      } catch (e) {
        localStorage.removeItem('form-data');
      }
    }
    return this.formData.value;
  }

  createNewServiceForm(value = ''): FormControl {
    return this.fb.control(value, [Validators.required]);
  }

  createNewRateForm(value = {}): FormGroup {
    return this.fb.group({
      value: this.fb.control(null, [Validators.required]),
      date: this.fb.control(null, [Validators.required]),
    });
  }

  initializeForm(newForm: FormGroup): any {
    const formData = this.getFormData();

    const services = newForm.get('entitlements.services') as FormArray;
    const ratesFirstYear = newForm.get('financialAssistance.firstYear.rates') as FormArray;
    const ratesSecondYear = newForm.get('financialAssistance.secondYear.rates') as FormArray;

    formData?.entitlements?.services?.forEach((service:string) => {
      services?.push(this.createNewServiceForm(service));
    });
    formData?.financialAssistance?.firstYear?.rates.forEach((rate: any) => {
      ratesFirstYear?.push(this.createNewRateForm(rate));
    });
    formData?.financialAssistance?.secondYear?.rates.forEach((rate: any) => {
      ratesSecondYear?.push(this.createNewRateForm(rate));
    });

    return newForm.patchValue(formData);
  }


  // todo auslagern in validatorservice
  private emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const hasAtSymbol = value && value.includes('@');
      return hasAtSymbol ? null : { 'emailInvalid': true };
    };
  }

  private postalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const valid = /^\d{5}$/.test(value);
      return valid ? null : { 'postalCodeInvalid': true };
    };
  }

  private mustBeTrueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      return value ? null : { 'mustBeTrue': true };
    };
  }

  private websiteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;

      if (!value) {
        return null;
      }
      return value.startsWith('www.') ? null : { 'websiteFormat': true };
    };
  }
}
