<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizard.sponsoringEnd.title')"></app-wizard-header>

  <div id="sponsoring-wizard-end-date" class="row">
    <div class="container">
      <div class="col-lg-8 col-md-8 col-sm-8">
        <mat-card class="card">
          <p class="font-c2 text-center"> {{t('wizard.sponsoringEnd.description')}}</p>
          <div class="col-md-8 col-lg-8 col-sm-8">
            <mat-calendar [selected]="form.get('sponsoringValue.sponsoringEndDate')?.value"
                          (selectedChange)="updateFormDate($event)"
                          [minDate]="setMinDate()"
                          [maxDate]="setMaxDate()">
            </mat-calendar>
          </div>
        </mat-card>
      </div>
    </div>

    <mat-error class="font-c3" *ngIf="this.validationService.isFieldInvalid('sponsoringValue.sponsoringEndDate', form)">
      {{form.get('sponsoringValue.sponsoringEndDate')?.hasError('required') ?
      t('error.required') : t('error.endBeforeStart', {parameter: (this.form.get('sponsoringValue.startDate')?.value | date: 'DD.MM.YYYY')} )}}
    </mat-error>
  </div>
  {{this.test}}
  <app-wizard-footer [newForm]="form" [splitForm]="this.validationService.isEndDateInvalid(form)"></app-wizard-footer>
</ng-container>
