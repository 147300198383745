<div id="detailViews">
  <ng-container *ngIf="activeUserRole === UserRoleEnum.APPLICANT">
    <app-applicant-detailoverview></app-applicant-detailoverview>
  </ng-container>

  <ng-container *ngIf="activeUserRole === UserRoleEnum.VKF">
    <app-vkf-detailoverview></app-vkf-detailoverview>
  </ng-container>
</div>

