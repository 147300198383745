import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WizardService } from '../../../shared/services/wizard-service/wizard.service';
import { FormService } from "../../../shared/services/form-service/form.service";
import { TextButtonComponent } from '../../../shared/components/button/text-button/text-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { WizardHeaderComponent } from '../wizard-header/wizard-header.component';
import { WizardFooterComponent } from '../wizard-footer/wizard-footer.component';
import {MatError, MatFormField} from '@angular/material/form-field';
import { NgForOf, NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { HintComponent } from "../../../shared/components/hint/hint.component";
import { IconTextButtonComponent } from "../../../shared/components/button/icon-text-button/icon-text-button.component";
import { MatRadioButton, MatRadioChange, MatRadioGroup } from "@angular/material/radio";
import { StepperComponent } from "../../../shared/components/stepper/stepper.component";
import { ValidationService } from "../../../shared/services/validation-service";

@Component({
  selector: 'app-wizard-sponsoring-entitelments',
  standalone: true,
    imports: [
        TextButtonComponent,
        TranslocoModule,
        WizardHeaderComponent,
        ReactiveFormsModule,
        WizardFooterComponent,
        MatFormField,
        NgForOf,
        MatInput,
        NgIf,
        HintComponent,
        IconTextButtonComponent,
        MatRadioButton,
        MatRadioGroup,
        StepperComponent,
        MatError
    ],
  templateUrl: './entitlements.html',
  styleUrls: ['./entitlements.scss']
})
export class Entitlements implements OnInit {
  form: FormGroup;

  constructor(public wizardService: WizardService, public formService: FormService,
              private fb: FormBuilder, public validationService: ValidationService) {
    this.form = this.formService.buildForm();
  }

  ngOnInit(): void {
    this.formService.initializeForm(this.form);
    this.ensureMinimumServices(2);
  }

  get services(): FormArray {
    return this.form.get('entitlements.services') as FormArray;
  }

  createNewServiceForm(value = ''): FormControl {
    return this.fb.control(value, Validators.minLength(10));
  }
  createNewServiceRequiredForm(value = ''): FormControl {
    return this.fb.control(value, [Validators.required, Validators.minLength(10)]);
  }

  addNewLeistung(): void {
    this.services.push(this.createNewServiceForm(''));
  }

  isHospitalityServiceAvailable(event: MatRadioChange): void {
    if(event.value === false){
      this.form.get('entitlements.hospitality.hospitalityService')?.setValue(null)
    }

    this.formService.setFormData(this.form);
  }

  isExclusivitySelected(event: MatRadioChange): void {
    this.formService.setFormData(this.form);
  }

  private ensureMinimumServices(min: number): void {
    while (this.services.length < min) {
      this.services.push(this.createNewServiceRequiredForm());
    }
  }
}
