import {Component, OnInit} from '@angular/core';
import {WizardService} from "../../../shared/services/wizard-service/wizard.service";
import {TextButtonComponent} from "../../../shared/components/button/text-button/text-button.component";
import {TranslocoDirective} from "@ngneat/transloco";
import {WizardHeaderComponent} from "../wizard-header/wizard-header.component";
import {NgForOf, NgIf} from "@angular/common";
import {
  KeyValueCardComponentComponent
} from "../../../shared/components/key-value-card-component/key-value-card-component.component";
import {FileUploaderComponent} from "../../../shared/components/file-uploader/file-uploader.component";
import {FileViewerComponent} from "../../../shared/components/file-viewer/file-viewer.component";
import {HintComponent} from "../../../shared/components/hint/hint.component";
import {FormArray, FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {
  FinancialAssistanceKeyValueComponent
} from "../../../shared/components/financial-assistance-table-component/financial-assistance-key-value-component";
import {FormService} from "../../../shared/services/form-service/form.service";
import {DataService} from "../../../core/services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StepperComponent} from "../../../shared/components/stepper/stepper.component";
import {FileUploadService} from "../../../shared/components/file-uploader/file-uploader-service";

@Component({
  selector: 'app-wizard-sponsoring-conclusion',
  standalone: true,
    imports: [
        TextButtonComponent,
        TranslocoDirective,
        WizardHeaderComponent,
        NgIf,
        NgForOf,
        KeyValueCardComponentComponent,
        FileUploaderComponent,
        FileViewerComponent,
        HintComponent,
        FinancialAssistanceKeyValueComponent,
        ReactiveFormsModule,
        StepperComponent
    ],
  templateUrl: './conclusion.html',
  styleUrl: './conclusion.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class Conclusion implements OnInit{
  form = this.formService.buildForm();
  get dataUploads(): FormArray {
    return this.form.get('dataUpload') as FormArray;
  }

  constructor(public wizardService: WizardService,
              public formService: FormService,
              public dataService: DataService,
              public router: Router,
              private route: ActivatedRoute,
              private fileUploadService: FileUploadService,
              private fb: FormBuilder
  ) {
    this.formService.initializeForm(this.form);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const state: any = history.state;
      if(state.applicationId){
        this.getDataForSelectedApplication(state.applicationId);
      }
    });
  }

  getDataForSelectedApplication(applicationId: string): void {
    this.dataService.getApplicationById(applicationId).subscribe((result) => {
      this.form.patchValue(result);
    })
  }

  uploadAllFiles(files: File[]): void {
    files.forEach(file => {
      this.dataUploads.push(new FormControl(this.fileToFileMetadata(file)));
    });
    this.saveFormData();
  }

  private fileToFileMetadata(file: File): any {
    return {
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type
    };
  }

  private saveFormData(): void {
    const formData = this.form.getRawValue();
    localStorage.setItem('form-data', JSON.stringify(formData));
  }

  createApplication() {
    const formData = this.formService.getFormData();
    const dataArray = this.form.get('dataUpload') as FormArray;
    const dataToUpload = this.fileUploadService.getData();

    dataToUpload.forEach(dataItem => {
      dataArray.push(this.fb.control(dataItem));
    });
    formData.dataUpload = dataToUpload;
    this.dataService.postApplication(formData).subscribe({
      next: () => {
        this.wizardService.navigateToNextPage();
      },
      error: (err) => {
        console.error('An error occurred:', err);
      }
    });
  }

}
