<ng-container *transloco="let t" [formGroup]="form">
  <app-stepper></app-stepper>
  <app-wizard-header [title]="t('wizardTitles.conclusion')"></app-wizard-header>

  <div class="container">
      <app-key-value-card-component [title]="t('wizard.sponsoringConfirmation.tableHeader')"
                                    [requestedFormGroupName]="'sponsoringValue'">
      </app-key-value-card-component>

      <app-key-value-card-component [isEditable]="true"
                                    [title]="t('wizard.sponsoringApplicant.title')"
                                    [requestedFormGroupName]="'applicant'">
      </app-key-value-card-component>



      <app-key-value-card-component [isEditable]="true" [title]="t('wizard.sponsored.title')" [requestedFormGroupName]="'sponsored'"></app-key-value-card-component>
      <app-financial-assistance-key-value-component [isEditable]="true"></app-financial-assistance-key-value-component>
      <app-key-value-card-component [isEditable]="true" [title]="t('wizard.entitlements.title')" [requestedFormGroupName]="'entitlements'"></app-key-value-card-component>

      <ng-container id="dataUpload">
        <app-key-value-card-component [title]="t('wizard.upload.title')" [requestedFormGroupName]="'dataUpload'"></app-key-value-card-component>
        <div class="container" id="data-upload">
          <app-file-uploader></app-file-uploader>
        </div>
      </ng-container>
    <app-text-button class="button saveButton text-center"
                     [isPrimaryButton]="true" [buttonText]="'Antrag stellen'"
                     (click)="createApplication()">
    </app-text-button>
  </div>


</ng-container>
