import {Component, Input} from '@angular/core';
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {TranslocoModule} from "@ngneat/transloco";
import {IconService} from "../../services/IconService";
import {IconsComponent} from "../icons/icons.component";
import {MatCard} from "@angular/material/card";

@Component({
  selector: 'app-hint',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    MatIcon,
    TranslocoModule,
    IconsComponent,
    MatCard,
    NgIf
  ],
  templateUrl: './hint.component.html',
  styleUrl: './hint.component.scss'
})
export class HintComponent {
  @Input() hintDescription = '';
  @Input() additionalTitle = '';
  @Input() additionalContent = '';

  constructor(private iconService: IconService) {
  }

}
