import {Injectable} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import {FormService} from "../form-service/form.service";
import {ValidationService} from "../validation-service";

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  private readonly pageRoutes: { [key: number]: string } = {
    1: '/duration',
    2: '/sponsoring-value',
    3: '/start-date',
    4: '/end-date',
    5: '/confirmation',
    6: '/applicant',
    7: '/sponsored',
    8: '/financial-assistance',
    9: '/entitlements',
    10: '/consent',
    11: '/file-upload',
    12: '/conclusion',
    13: '/next-steps'
  };

  currentPage: number = 1;
  allPages: number = Object.keys(this.pageRoutes).length;
  buttonClicked = false;

  constructor(private router: Router, private formService: FormService, private validationService: ValidationService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCurrentPage();
    });
  }

   updateCurrentPage(): void {
    const currentRoute = this.router.url;
    const pageEntry = Object.entries(this.pageRoutes).find(([_, route]) => route === currentRoute);
    if (pageEntry) {
      this.currentPage = +pageEntry[0];
    } else {
      this.currentPage = 1;
    }
  }

  navigateToNextPage(): void {
    if (this.currentPage < this.allPages) {
      this.currentPage++;
      this.router.navigate([this.pageRoutes[this.currentPage]]);
    }
  }

  navigateToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.router.navigate([this.pageRoutes[this.currentPage]]);
    }
  }

  saveFormInStorage(form: FormGroup, controls?: Array<any>): void {
    if(!controls){
      this.navigateToNextPage();
    }
      controls?.forEach(control => {
        if (control && !control.disabled) {
          control.markAsTouched();
        }
      });
      const invalidControls = controls?.some(control => control?.invalid);
      this.setCommentDetails(form);
      if(form && !invalidControls) {
        this.formService.setFormData(form);
        this.navigateToNextPage();
      }
  }

  setCommentDetails(form: FormGroup): void {

    /*
    // const loggedInUser = ''
    const createdAt = new Date().toISOString(); // Erstellungsdatum
    const userRole = loggedInUser.role; // Beispiel: Rolle des Benutzers

    const extendedFormData = {
      ...form.value,
      user: {
        id: loggedInUser.id,
        name: loggedInUser.name,
        role: userRole,
      },
      createdAt: createdAt,
    };*/
  }

}
