import { Component, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForOf, NgIf } from '@angular/common';
import { IconsComponent } from '../icons/icons.component';
import { MatCard } from '@angular/material/card';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { FormService } from '../../services/form-service/form.service';
import {FileUploadService} from "./file-uploader-service";

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    NgIf,
    IconsComponent,
    MatCard,
    TextButtonComponent,
    TranslocoDirective,
    NgForOf,
    FileViewerComponent,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  form = this.formService.buildForm();
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  selectedFiles: File[] = [];
  isDragOver = false;

  constructor(private http: HttpClient, public formService: FormService, public fileUploadService: FileUploadService) {
    this.initializeForm();
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    this.processFiles(files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.processFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  private processFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      this.readFile(file);
    }
    this.emitSelectedFiles();
  }

  private readFile(file: File) {
    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result as string;
      const fileData = {
        name: file.name,
        type: file.type,
        size: file.size,
        content: fileContent,
      };
      this.fileUploadService.setData(fileData);
    };
    reader.readAsDataURL(file);
  }

  private emitSelectedFiles() {
    this.filesSelected.emit(this.selectedFiles);
  }

  private initializeForm(): void {
    const formData = this.formService.getFormData();

    if (formData.dataUpload) {
      this.selectedFiles = formData.dataUpload;
    }
    this.form.patchValue(formData);
  }
}
