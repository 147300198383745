<ng-container *ngIf="isPrimaryButton else secondaryButton">
  <button disabled="{{isButtonReadOnly}}" class="primary" mat-button (click)="clicked()">
    <p  class="font-c1">{{buttonText}}</p>
  </button>
</ng-container>


<ng-template #secondaryButton>
  <button disabled="{{isButtonReadOnly}}" class="secondary" mat-button (click)="clicked()">
    <p class="font-c1">{{buttonText}}</p>
  </button>
</ng-template>
